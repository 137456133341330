import {memo, useMemo} from 'react';
import STopBar from './topBar.styled';
import {Avatar, ContextMenu, Div, TitledText} from '@/Website/Components/UI';
import {useRootStore} from '@/Store/root/root.selector';
import {useLogout} from '@/Hooks';

const TopBar = () => {
  const {user} = useRootStore().selectors;
  const logout = useLogout();
  const menuOptions = useMemo(() => [{onSelect: logout, option: 'Logout'}], []);
  return user ? (
    <STopBar className="TopBar">
      <Div alignItems="center">
        <ContextMenu disableHoverEffect options={menuOptions} dotsColor="White" />
        <TitledText
          style={{flexDirection: 'column'}}
          title={{value: user.name, type: 'Text2', fontWeight: 800, color: 'White'}}
          text={{value: user.website, type: 'Text3', fontWeight: 400, color: 'MediumGray'}}
        />
      </Div>

      <Avatar borderColor="BlackOcean" image={user?.logo || ''} size="big" />
    </STopBar>
  ) : null;
};
export default memo(TopBar);
