import {memo, useMemo} from 'react';
import {STalentTab} from '@/Website/Components/Portals/Talent/talent.styled';
import {useTalentWallet} from '@/Website/Components/Portals/Talent';
import {Box, Div, TitledText} from '@/Website/Components/UI';
import {dateToLocalDate} from '@/Services/date.service';
import {ColorType} from '@/Styles/types';

const TalentWallet = () => {
  const {wallet} = useTalentWallet();
  const DATA = useMemo(
    () =>
      [
        {label: 'Amount', color: 'Orange', value: `$${wallet?.amount || 0}`},
        {label: 'Total income', color: 'Blue', value: `$${wallet?.totalIncome || 0}`},
        {label: 'Total withdraw', color: 'Purple', value: `$${wallet?.totalWithdraw || 0}`},
        {label: 'Last update', color: 'Success', value: dateToLocalDate(wallet?.updatedAt)},
      ] as Array<{label: string; color: ColorType; value: any}>,
    [wallet]
  );
  return (
    <STalentTab>
      <Box backgroundColor="AntiqueWhiteLight">
        <Div justifyContent="space-between">
          {DATA.map((item, i) => (
            <TitledText
              key={i}
              style={{flexDirection: 'column'}}
              title={{value: item.label}}
              text={{type: 'Text2', color: item.color, value: item.value}}
            />
          ))}
        </Div>
      </Box>
    </STalentTab>
  );
};
export default memo(TalentWallet);
