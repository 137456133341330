import React, {useCallback} from 'react';

const useReduceState = <T>(setState: React.Dispatch<React.SetStateAction<T>>, resetSkip?: boolean) => {
  return useCallback(
    (prop: keyof T, value: any) => {
      setState(prev => {
        if (Object.hasOwn(prev as Object, 'skip') && resetSkip) {
          return {...prev, [prop]: value, skip: 0};
        }
        return {...prev, [prop]: value};
      });
    },
    [setState]
  );
};

export type ReduceStateReturnType<T> = (prop: keyof T, value: any) => void;

export default useReduceState;
