import {TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import styled, {css} from 'styled-components';

const Row = styled(TableRow)`
  ${({theme}) => css`
    &:hover {
      background-color: transparent !important;
    }
    .loading-background {
      height: 42px;
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      background-size: 500px 100px;
      animation-name: ${theme.Animations.movingGradient};
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  `}
`;
const LoadingRows: React.FC<{colNumber: number}> = ({colNumber}) => {
  const renderRows = Array(20).fill(Math.floor(Math.random()));
  return (
    <>
      {renderRows.map((el, i) => (
        <Row key={i}>
          <TableCell colSpan={colNumber}>
            <div className="loading-background"></div>
          </TableCell>
        </Row>
      ))}
    </>
  );
};
export default LoadingRows;
