import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InitialPortalStateType} from './types';

const initialState: InitialPortalStateType = {
  orderPortal: {
    isShowOrderPortal: false,
    order: undefined,
    orderPortalCallBack: undefined,
  },
  talentPortal: {
    isShowTalentPortal: false,
    talent: undefined,
    talentPortalCallBack: undefined,
  },
};

const slice = createSlice({
  name: 'portals',
  initialState: initialState as InitialPortalStateType,
  reducers: {
    setOrderPortal(state, action: PayloadAction<InitialPortalStateType['orderPortal']>) {
      state.orderPortal = {...state.orderPortal, ...action.payload};
    },
    setTalentPortal(state, action: PayloadAction<InitialPortalStateType['talentPortal']>) {
      state.talentPortal = {...state.talentPortal, ...action.payload};
    },
  },
});
export const portalsReducer = slice.reducer;
export const portalsActions = slice.actions;
