import styled, {css} from 'styled-components';

export const Component = styled.div<{
  isError: boolean;
  isDisabled: boolean;
  withoutIcon: boolean;

}>`
  ${({theme, withoutIcon, isDisabled, isError}) => css`
    position: relative;
    width: 100%;

    .input-group {
      width: 100%;
      position: relative;
      margin: 28px 0 0;
    }

    .input {
      padding: ${withoutIcon ? `11px 40px 9px 10px` : `9px 45px`};
      width: 100%;
      font-size: 0.7rem;
      font-weight: 400;
      font-family: ${theme.FontFamily.Outfit};
      border: none;
      border-radius: 15px;
      color: #000000;
      background-color: #f9f9f9;
      outline: 1px solid ${isError ? theme.Color.Alert : '#e7e6e6'};
    }

    .input-label {
      position: absolute;
      top: 0;
      left: 30px;
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.7rem;
      transform: translate(10px, 10px);
      transition: transform 0.25s;
      visibility: hidden;
      color: #d3c2c2;
      background-color: inherit;
    }

    .input::-webkit-input-placeholder {
      color: #a1a1a1;
    }

    .input:focus + .input-label,
    .input:valid + .input-label {
      visibility: visible;
      background-color: inherit;
      transform: translate(-36px, -17px) scale(0.7);
      color: #a1a1a1;
      font-size: 0.8rem;
      padding-inline: 5px;
      border-radius: 15px;
    }

    .input:is(:focus, :valid) {
      outline-color: ${isError ? theme.Color.Alert : '#f2c5fc'};

      &::-webkit-input-placeholder {
        visibility: hidden;
      }
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .eye {
      height: 15px;
      right: 25px;
      cursor: pointer;
      margin-top: 10px;
    }

    .mail,
    .password {
      height: 15px;
      left: 9px;
      margin-top: 9px;
    }

    .mail,
    .password,
    .eye {
      position: absolute;
      opacity: 0.2;
    }

    .clear-input {
      position: absolute;
      cursor: pointer;
      color: ${theme.Color.MediumGray};
      top: 9px;
      right: 9px;
      font-size: 0.6rem;

      &:hover {
        color: black;
      }
    }

    .error-msg {
      position: absolute;
      font-size: 0.5rem;
      background-color: white;
      padding: 0 5px;
      border: 1px solid #e2e2e2;
      left: 15px;
      bottom: -7px;
      border-radius: 15px;
    }
    ${isDisabled &&
    css`
      pointer-events: none;
      .input {
        background-color: #eaeaea;
      }
      .clear-input {
        display: none;
      }
    `}
  `}
`;
