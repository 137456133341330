import {useCallback} from 'react';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Talent} from '@/API/Talents/talents.types';

const useOpenTalent = () => {
  const {setTalentPortal} = usePortalsStore().actions;
  return useCallback((talent: Talent) => {
    setTalentPortal({
      talent,
      isShowTalentPortal: true,
    });
  }, []);
};
export default useOpenTalent;
