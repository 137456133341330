import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {
  OrdersFilters,
  OrdersService,
  OrdersTable,
  useOrganizationOrders,
} from '@/Website/Pages/Dashboard/Orders/index';
import {Box, Div, ElementHeadline, Pagination} from '@/Website/Components/UI';
import useDebouncedValue from '@/Hooks/useDebouncedValue';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const Orders = () => {
  const defaultFilterState = useMemo(() => OrdersService.defaultTableFilterState, []);
  const [filterState, setFilterState] = useState(defaultFilterState);
  const {setOrderPortal} = usePortalsStore().actions;
  const {debouncedValue} = useDebouncedValue(filterState, 500);
  const {isLoading, orders, reloadOrders} = useOrganizationOrders(debouncedValue);
  const handleSkip = useCallback((skip: number) => setFilterState(prev => ({...prev, skip})), []);

  useEffect(() => {
    setOrderPortal({orderPortalCallBack: reloadOrders});
  }, [filterState]);
  return (
    <Box>
      <Div flexDirection="column" height={400}>
        <ElementHeadline
          options={[{onSelect: reloadOrders, option: 'Reload'}]}
          title={`Total orders: ${orders.count}`}
        />
        <OrdersFilters setFilters={setFilterState} filters={filterState} />
        <OrdersTable isLoading={isLoading} orders={orders.orders} />
        <Pagination pagination={{limit: filterState.limit || 50, setSkip: handleSkip, count: orders.count}} />
      </Div>
    </Box>
  );
};
export default memo(Orders);
