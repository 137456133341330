import {useCallback} from 'react';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Order} from '@/API/Orders/orders.types';

const useOpenOrder = () => {
  const {setOrderPortal} = usePortalsStore().actions;
  return useCallback((order: Order) => {
    setOrderPortal({
      order,
      isShowOrderPortal: true,
    });
  }, []);
};

export default useOpenOrder;
