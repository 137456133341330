import {memo} from 'react';
import {Curtain, Div, Line} from '@/Website/Components/UI';
import {
  OrderCampaign,
  OrderPrice,
  useCloseOrder,
  useGetOrder,
  OrderResponse,
  OrderTalent,
} from '@/Website/Components/Portals/Order/index';

const Order = () => {
  const closeOrder = useCloseOrder();
  const {isLoading, order, reloadOrder} = useGetOrder();

  return (
    <Curtain
      curtainConfig={{
        top: '0px',
        right: '0px',
        height: '100vh',
        width: '600px',
        slideFrom: 'left',
      }}
      headLineProps={{isLoading, title: `Order ${order?.id || ''}`, onClose: closeOrder}}
    >
      <Div flexDirection="column" gap={40}>
        <Div flexDirection="column">
          {order && <OrderResponse onPassageCallback={reloadOrder} order={order} />}
          <Line />
        </Div>

        {order && <OrderTalent isLoading={isLoading} order={order} />}

        {order?.id && order?.talent && (
          <OrderPrice
            isLoading={isLoading}
            orderDto={{
              orderId: order.id,
              price: order?.talentPrice,
              talentId: order?.talent,
              status: order?.status,
            }}
          />
        )}

        <OrderCampaign isLoading={isLoading} campaign={order?.campaign} />
      </Div>
    </Curtain>
  );
};
export default memo(Order);
