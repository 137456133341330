import {useRootStore} from '@/Store/root/root.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useSetAuth = () => {
  const {setIsAuth, setUser} = useRootStore().actions;
  return useApiLoader(async () => {
    await API.Organizations.getOwnOrganization().then(organization => {
      if (!organization) throw new Error('Invalid permissions');
      setUser(organization);
      setIsAuth(true);
    });
  }, []);
};
export default useSetAuth;
