const envValues = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MSG_SENDER_ID: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,

  ADMIN_MS: process.env.REACT_APP_ADMIN_MS,
  MEDIA_ROUTE: process.env.REACT_APP_MEDIA_ROUTE,
  DISCOVERY_MS: process.env.REACT_APP_DISCOVERY_MS,
  ORGANIZATION_MS: process.env.REACT_APP_ORGANIZATIONS_MS,
  // ORGANIZATION_MS: 'http://localhost:3009',
  COMMUNICATION_MS: process.env.REACT_APP_COMMUNICATION_MS,
  BASE_ADMIN: process.env.REACT_APP_BASE_ADMIN,

  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY_ID_SECURE: process.env.REACT_APP_AWS_ACCESS_KEY_ID_SECURE,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_SECRET_ACCESS_KEY_SECURE: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_SECURE,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_COGNITO_USER_POOL: process.env.REACT_APP_AWS_COGNITO_USER_POOL,
  AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
  AWS_BUCKET_SECURE: process.env.REACT_APP_AWS_BUCKET_SECURE,
};
export const CONFIG_ENV = (key: keyof typeof envValues): string => {
  return envValues[key] as string;
};
