import {ReactComponent as DetailsIcon} from '@/Assets/Icons/talent/details.svg';
import {ReactComponent as SocialsIcon} from '@/Assets/Icons/talent/socials.svg';
import {ReactComponent as WalletIcon} from '@/Assets/Icons/talent/wallet.svg';
import {ReactComponent as FinancialIcon} from '@/Assets/Icons/talent/financial.svg';
import {ReactComponent as OrderIcon} from '@/Assets/Icons/talent/order.svg';
import {ReactComponent as SendIcon} from '@/Assets/Icons/sidebar/send.svg';
import {ReactComponent as PreOrderIcon} from '@/Assets/Icons/campaign/preorder.svg';
import {ReactComponent as ConnectIcon} from '@/Assets/Icons/talent/connect.svg';
import {ReactComponent as AnalyticIcon} from '@/Assets/Icons/campaign/monitoring.svg';
import {ReactComponent as InsightIcon} from '@/Assets/Icons/talent/stats.svg';
import {TalentUpdateDTO} from '@/API/Talents/talents.types';
import React from 'react';

export interface TalentUpdatable {
  talentUpdate: TalentUpdateDTO;
  setTalentUpdate: React.Dispatch<React.SetStateAction<TalentUpdateDTO>>;
}
export const TalentService = {
  tabs: [
    {label: 'Details', icon: DetailsIcon},
    {label: 'Socials', icon: SocialsIcon},
    {label: 'Orders', icon: OrderIcon},
    {label: 'Wallet', icon: WalletIcon},
    // {label: 'PreOrder', icon: PreOrderIcon},
    // {label: 'Analytics', icon: AnalyticIcon},
    // {label: 'Last activity', icon: ConnectIcon},
    {label: 'Financial docs', icon: FinancialIcon},
    // {label: 'Emails', icon: SendIcon},
    // {label: 'Insights', icon: InsightIcon},
  ],
};
