import {Box, TitledText} from '@/Website/Components/UI';
import React from 'react';

const OrderRevision: React.FC<{revision?: {reason: string; changes: string}}> = ({revision}) => {
  return revision ? (
    <Box>
      <TitledText
        style={{flexDirection: 'column'}}
        title={{value: 'Reason: '}}
        text={{value: revision.reason}}
      />
      <TitledText
        style={{flexDirection: 'column'}}
        title={{value: 'Changes: '}}
        text={{value: revision.changes}}
      />
    </Box>
  ) : null;
};
export default OrderRevision;
