import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {TalentUpdateDTO} from './agencyTalents.types';
import {CONFIG_ENV} from '@/Config/config.env';
import {CONTROLLERS} from '../CONTROLLERS';

class AgencyTalentsApi {
  readonly api: AxiosInstance = new FactoryAxios(
    CONFIG_ENV('ORGANIZATION_MS'),
    CONTROLLERS.AgencyTalents
  ).getInstance();

  //
  async updateTalent(talentId: string, updateDTO: TalentUpdateDTO): Promise<any> {
    return await this.api.post(`/wallet/${talentId}`, updateDTO).then(res => res.data);
  }
  //
  async getTalentOrderRespond(talentId: string, orderId: number): Promise<any> {
    return await this.api.get(`/respond/${talentId}/${orderId}`).then(res => res.data);
  }
  //
  async getTalentOrderDecline(talentId: string, orderId: number): Promise<any> {
    return await this.api.get(`/decline/${talentId}/${orderId}`).then(res => res.data);
  }
  //
  async getTalentOrderStats(talentId: string): Promise<any> {
    return await this.api.get(`/order-stats/${talentId}`).then(res => res.data);
  }
  //
  async getTalentOrderProducts(talentId: string, orderId: number): Promise<any> {
    return await this.api.get(`/order-products/${talentId}/${orderId}`).then(res => res.data);
  }

  //
  async getTalentFinancialDocs(talentId: string, orderId: number): Promise<any> {
    return await this.api.get(`/order-products/${talentId}/${orderId}`).then(res => res.data);
  }
}

export default AgencyTalentsApi;
