import {useCallback} from 'react';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Talent} from '@/API/Talents/talents.types';

const useCloseTalent = () => {
  const {setTalentPortal} = usePortalsStore().actions;
  return useCallback(() => {
    setTalentPortal({
      isShowTalentPortal: false,
    });
  }, []);
};
export default useCloseTalent;
