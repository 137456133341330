import {TableBody} from '@material-ui/core';
import LoadingRows from '@/Website/Components/UI/Table/LoadingRows';

interface BodyProps {
  loader: {isShow?: boolean; colNumber: number};
  children: React.ReactNode;
}

const TBody: React.FC<BodyProps> = ({loader: {colNumber, isShow: isShowLoader}, children}) => {
  return <TableBody>{isShowLoader ? <LoadingRows colNumber={colNumber} /> : children}</TableBody>;
};

export default TBody;
