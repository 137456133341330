import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {API} from '@/API/API';
import {useRootStore} from '@/Store/root/root.selector';
import {OrganizationOrdersResponse, OrganizationOrdersSearchParams} from '@/API/Orders/orders.types';

const useOrganizationOrders = (params?: Omit<OrganizationOrdersSearchParams, 'organizationId'>) => {
  const {user} = useRootStore().selectors;
  const [orders, setOrders] = useState<OrganizationOrdersResponse>({count: 0, orders: []});
  const Loading = useBooleanState();
  const fetch = useApiLoader(
    async () => {
      if (user?.id) {
        Loading.turnOn();
        const query: OrganizationOrdersSearchParams = {
          organizationId: user.id,
        };
        if (params?.campaignID) query.campaignID = params.campaignID;
        if (params?.orderStatus) query.orderStatus = params.orderStatus;
        if (params?.talentId) query.talentId = params.talentId;
        if (params?.limit) query.limit = params.limit;
        if (params?.skip) query.skip = params.skip;

        await API.Orders.getOrdersByOrganization(query)
          .finally(Loading.turnOff)
          .then(res => setOrders(res));
      }
    },
    [params],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    orders,
    isLoading: Loading.isTurn,
    reloadOrders: fetch,
  };
};

export default useOrganizationOrders;
