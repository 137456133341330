import React, {useCallback} from 'react';
import {TalentUpdatable} from '@/Website/Components/Portals/Talent/talent.service';
import {Box, InputMedia} from '@/Website/Components/UI';
import {CONFIG_ENV} from '@/Config/config.env';
import {useDeleteTalentMedia, useUploadTalentMedia} from '@/Website/Components/Portals/Talent';

const TalentMediaFiles: React.FC<TalentUpdatable> = ({talentUpdate, setTalentUpdate}) => {
  const deleteTalentMedia = useDeleteTalentMedia();
  const uploadTalentMedia = useUploadTalentMedia();

  const handleUploadTalentVideo = useCallback(
    async (media: any) => {
      if (talentUpdate.profile.avatarVideo) await handleDeleteTalentVideo();
      await uploadTalentMedia({file: media, mediaType: 'video'}).then((res: any) => {
        setTalentUpdate(prev => ({...prev, profile: {...prev.profile, avatarVideo: res}}));
      });
    },
    [setTalentUpdate, uploadTalentMedia]
  );

  const handleUploadTalentPhoto = useCallback(
    async (media: any) => {
      await uploadTalentMedia({file: media, mediaType: 'avatar'}).then((res: any) => {
        setTalentUpdate(prev => ({...prev, profile: {...prev.profile, avatar: res}}));
      });
    },
    [setTalentUpdate, uploadTalentMedia]
  );

  const handleDeleteTalentAvatar = useCallback(
    async () =>
      await deleteTalentMedia({filePath: talentUpdate.profile.avatar, mediaType: 'avatar'}).then(() => {
        setTalentUpdate(prev => ({...prev, profile: {...prev.profile, avatar: ''}}));
      }),
    [deleteTalentMedia, setTalentUpdate]
  );

  const handleDeleteTalentVideo = useCallback(
    async () =>
      await deleteTalentMedia({filePath: talentUpdate.profile.avatar, mediaType: 'video'}).then(() => {
        setTalentUpdate(prev => ({...prev, profile: {...prev.profile, avatarVideo: ''}}));
      }),
    [deleteTalentMedia, setTalentUpdate]
  );
  return (
    <Box backgroundColor="BlueLight" label="Media" flexDirection="column" justifyContent="space-evenly">
      <InputMedia
        onDelete={handleDeleteTalentAvatar}
        onUpload={handleUploadTalentPhoto}
        type="photo"
        mediaFile={talentUpdate.profile.avatar ? CONFIG_ENV('MEDIA_ROUTE') + talentUpdate.profile.avatar : ''}
      />
      <InputMedia
        onDelete={handleDeleteTalentVideo}
        onUpload={handleUploadTalentVideo}
        type="video"
        mediaFile={
          talentUpdate.profile.avatarVideo ? CONFIG_ENV('MEDIA_ROUTE') + talentUpdate.profile.avatarVideo : ''
        }
      />
    </Box>
  );
};
export default TalentMediaFiles;
