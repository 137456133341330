import React, {useState} from 'react';
import {useUpdateOrderLinkPost} from '@/Website/Components/Portals/Order';
import {Button, Div, Grid, Input, Text} from '@/Website/Components/UI';

const OrderLinkPostUpdater: React.FC<{afterLinkUpdated?: Function}> = ({afterLinkUpdated}) => {
  const [link, setLink] = useState<string>('');
  const updateLink = useUpdateOrderLinkPost(afterLinkUpdated);
  return (
    <Div flexDirection="column">
      <Text type="Text3" italic>
        Please update a link post in the field below:
      </Text>
      <Grid alignItems="flex-end" templateColumn="300px 150px" justifyContent="space-between">
        <Input autoFocus placeholder="Link post" value={link} onChange={e => setLink(e.target.value)} />
        <Button onClick={async () => await updateLink(link)} isDisabled={!link} size="medium" color="Success">
          UPDATE
        </Button>
      </Grid>
    </Div>
  );
};
export default OrderLinkPostUpdater;
