import styled, {css} from 'styled-components';
import {TextProps} from './Text';

export const Component = styled.div<TextProps>`
  ${({
    type,
    wordBreak,
    theme,
    fontWeight,
    color,
    italic,
    noWrap,
    textAlign,
    flex,
    flexBasis,
    lineThrough,
    cursor,
  }) => {
    return css`
      .text-loader {
        height: ${type ? `calc(${theme.TextType[type]} + 5px)` : '0.7rem'};
        border-radius: 15px;
        background: linear-gradient(to right, #e1e1e1 20%, #d0d0d0 50%, #dedddd 80%);
        background-size: 500px 100px;
        animation-name: ${theme.Animations.movingGradient};
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }

      font-family: ${theme.FontFamily.Outfit};
      font-size: ${type ? theme.TextType[type] : '0.7rem'};
      font-weight: ${fontWeight || 400};
      color: ${(color && theme.Color[color]) || 'inherit'};

      ${italic && 'font-style: italic;'}
      ${lineThrough &&
      css`
        -webkit-text-decoration-line: line-through; /* Safari */
        text-decoration-line: line-through;
      `}
      ${textAlign && `text-align: ${textAlign};`}
      ${flex && `flex: ${flex};`}
      ${flexBasis && `flex-basis: ${flexBasis};`}
      ${noWrap && `white-space: nowrap;`}
      ${cursor &&
      css`
        cursor: ${cursor};
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      `}
      ${wordBreak && `word-break: break-all;`}
    `;
  }}
`;
