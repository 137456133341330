import React, {memo} from 'react';
import styled, {css} from 'styled-components';
import {ColorType} from '@/Styles/types';

interface Props {
  lineColor?: ColorType;
}
const SLine = styled.div<Props>`
  ${({theme, lineColor}) => css`
    position: relative;
    height: 0.8px;
    width: 100%;
    margin: 15px 0;
    background-color: ${lineColor ? theme.Color[lineColor] : theme.Color.GrayLight};
  `}
`;

const Line: React.FC<Props> = props => {
  const {lineColor} = props;
  return <SLine className="Line" lineColor={lineColor}></SLine>;
};

export default memo(Line);
