import {useCallback} from 'react';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useCloseOrder = () => {
  const {setOrderPortal} = usePortalsStore().actions;
  return useCallback(() => {
    setOrderPortal({
      isShowOrderPortal: false,
    });
  }, []);
};

export default useCloseOrder;
