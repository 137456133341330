import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useRootStore} from '@/Store/root/root.selector';

const useDeleteTalents = () => {
  const {user} = useRootStore().selectors;
  const {talentPortalCallBack} = usePortalsStore().selectors.talentPortal;
  return useApiLoader(
    async (talents: Array<string>) => {
      if (user) {
        return await API.Talents.deleteAgencyTalents({talents, organizationID: user.id}).then(() => {
          talentPortalCallBack && talentPortalCallBack();
        });
      }
    },
    [user],
    {successMsg: 'Deleted'}
  );
};
export default useDeleteTalents;
