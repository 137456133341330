import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AgencyTalentsDTO, AgenciesCampaignsResponse} from './agency.types';
import {CONFIG_ENV} from '@/Config/config.env';
import {CONTROLLERS} from '../CONTROLLERS';

class AgencyApi {
  readonly api: AxiosInstance = new FactoryAxios(
    CONFIG_ENV('ORGANIZATION_MS'),
    CONTROLLERS.Agencies
  ).getInstance();

  //
  async addAgencyTalents(payload: AgencyTalentsDTO): Promise<any> {
    const {organizationID, talents} = payload;
    return await this.api
      .post(`/add-talents/${organizationID}`, {
        talents,
      })
      .then(res => res.data);
  }

  //
  async getAgencyCampaigns(organizationId: number): Promise<AgenciesCampaignsResponse> {
    return await this.api.get(`/all-campaigns/${organizationId}`).then(res => res.data);
  }
  //
  async getAgencyStats(organizationID: number): Promise<any> {
    return await this.api.post(`/stats/${organizationID}`).then(res => res.data);
  }
}

export default AgencyApi;
