import React from 'react';
import {Button, Div, Grid, Text} from '@/Website/Components/UI';
import {useSetOrderPassage} from '@/Website/Components/Portals/Order';

interface Props {
  onPassageCallback?: Function;
}
const OrderPassage: React.FC<Props> = ({onPassageCallback}) => {
  const passage = useSetOrderPassage(onPassageCallback);
  return (
    <Div flexDirection="column" gap={10}>
      <Text textAlign="center" type="Text2">
        Order pending approval:
      </Text>
      <Grid templateColumn="repeat(2, 200px)" justifyContent="space-between">
        <Button onClick={async () => await passage({passage: 'waiting-list'})} size="medium" color="Success">
          APPROVE
        </Button>
        <Button onClick={async () => await passage({passage: 'rejected'})} size="medium" color="Alert">
          REJECT
        </Button>
      </Grid>
    </Div>
  );
};
export default OrderPassage;
