import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import Router from './Router/Router';
import {STORE} from './Store/store';
import {THEME} from './Styles/theme';
import React from 'react';

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={STORE}>
        <ThemeProvider theme={THEME}>
          <Router />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};
