import React from 'react';
import {Avatar, Box, Div, Grid, Line, SocialIcon, Text, TitledText} from '@/Website/Components/UI';
import ColorService from '@/Services/color.service';
import {SocialService} from '@/Services/social.service';
import {OrderDetailed} from '@/API/Orders/orders.types';

interface Props {
  isLoading: boolean;
  order?: OrderDetailed;
}
const OrderTalent: React.FC<Props> = ({isLoading, order}) => {
  return order ? (
    <Box label="Talent">
      <Grid templateColumn="100px 1fr">
        <Avatar
          image={order?.talentProfile?.avatar}
          borderColor={ColorService.getColorTypeByStatus(order.status)}
          size="large"
        />
        <Div flexDirection="column" gap={10}>
          <TitledText
            style={{flexDirection: 'column'}}
            title={{type: 'Text3', value: 'Full name'}}
            text={{
              color: 'BlackOcean',
              type: 'Text1',
              value: order?.talentProfile?.user?.fullName,
              isLoading,
            }}
          />

          <TitledText
            style={{flexDirection: 'column'}}
            title={{type: 'Text3', value: 'Email'}}
            text={{color: 'BlackOcean', type: 'Text1', value: order.talentProfile.user.email, isLoading}}
          />
        </Div>
      </Grid>
      <Line />

      <Div
        gap={10}
        onClick={(e: any) => {
          e.stopPropagation();
          void SocialService.handlePlatformClick(order.talentInfo.url);
        }}
      >
        <SocialIcon isActive social={order.talentInfo.socialPlatform} size="small" />
        <Text cursor="pointer" color="MediumGray">
          {order.talentInfo.url}
        </Text>
      </Div>
    </Box>
  ) : null;
};
export default OrderTalent;
