import WithLazy from '@/HOC/WithLazy';
import {lazy} from 'react';
import {ROUTES} from '@/Router/ROUTES';

const Dashboard = WithLazy(lazy(() => import('@/Website/Pages/Dashboard/Dashboard')));

export const RoutesValues: Array<{
  path: string;
  index: boolean;
  Element: any;
}> = [{path: ROUTES.default, Element: Dashboard, index: true}];
