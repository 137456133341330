import styled, {css} from 'styled-components';
import {Props} from './Button';

export const Component = styled.div<Props>`
  ${({theme, color, size, isDisabled, fontColor}) => css`
    height: auto;
    display: flex;
    svg {
      height: 100%;
    }
    button {
      width: 100%;
      padding: 8px 40px;
      border-radius: 15px;
      box-shadow: rgb(34 34 34 / 20%) 0 5px 15px;
      font-family: ${theme.FontFamily.Outfit};
      font-weight: 600;
      font-size: 1rem;
      ${
        color &&
        `
        color: ${fontColor ? fontColor : 'white'};
        border: 1px solid ${theme.Color[color]};
        background-color: ${theme.Color[color]};
      `
      }
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: ${theme.Color.AliceBlue};
        color: ${fontColor ? fontColor : theme.Color[color]};
      }
      &:active {
        transform: translate(2px, 2px);
      }
      ${
        size === 'small' &&
        css`
          height: 23px;
          font-size: 0.7rem;
          padding: 1px 15px;
        `
      }
      ${
        size === 'medium' &&
        css`
          height: 35px;
          font-size: 0.7rem;
          padding: 3px 15px;
        `
      }
      ${
        size === 'large' &&
        css`
          height: 30px;
          font-size: 0.8rem;
          padding: 3px 20px;
        `
      }
      ${
        isDisabled &&
        css`
          pointer-events: none;
          border: 1px solid ${theme.Color.GrayLight};
          background-color: ${theme.Color.GrayLight};
          box-shadow: none;
        `
      }
      ${
        fontColor &&
        css`
          color: ${fontColor};
        `
      }
  `}
`;
