import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useUpdateOrderLinkPost = (callBack?: Function) => {
  const {order, orderPortalCallBack} = usePortalsStore().selectors.orderPortal;
  return useApiLoader(
    async (link: string) => {
      if (order?.id && order?.talent) {
        return await API.Orders.updateOrderPostLink({
          link,
          orderId: order.id,
          talentId: order.talent,
        }).then(async () => {
          if (orderPortalCallBack) await orderPortalCallBack();
          if (callBack) await callBack();
        });
      }
    },
    [order],
    {successMsg: 'Link updated'}
  );
};

export default useUpdateOrderLinkPost;
