import styled, {css} from 'styled-components';

const STopBar = styled.div`
  ${({theme}) => css`
    position: absolute;
    align-items: center;
    width: max-content;
    height: 50px;
    right: 20px;
    top: 20px;
    z-index: 1;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 15px;
    background-color: ${theme.Color.DarkBlue};
  `}
`;
export default STopBar;
