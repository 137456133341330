import {ColorType} from '@/Styles/types';
import React, {ReactNode} from 'react';
import SBox from '@/Website/Components/UI/Box/box.styled';
import {FlexProps} from '@/Styles/theme';

export interface BoxProps
  extends FlexProps,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: ReactNode;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  label?: string;
}

const Box: React.FC<BoxProps> = props => {
  const {children, label, ...rest} = props;
  return (
    //@ts-ignore
    <SBox {...rest}>
      {label && <div className="boxLabel">{label}</div>}
      {children}
    </SBox>
  );
};

export default Box;
