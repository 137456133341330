import styled, {css} from 'styled-components';

export const Component = styled.div<{isOn: boolean}>`
  ${({isOn, theme}) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    .outer-div {
      width: 38px;
      height: 22px;
      padding: 0;
      margin: 8px;
      border-radius: 13px;
      background-color: ${isOn ? theme.Color.DarkGray : `rgb(233, 233, 234)`};
      display: flex;
      align-items: center;
      ${!isOn && `border: 1px solid ${theme.Color.GrayLight};`}
      .inner-div {
        position: relative;
        background-color: #ffffff;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        margin: 3px 0;
        box-shadow: 0 1px 2px #939393;
        transition: left 0.1s ease;
        left: ${isOn ? `18px` : `2px`};
      }
    }
    .option {
      font-size: 0.8rem;
      color: ${!isOn ? `#d3d3d3` : `inherit`};
      font-family: ${theme.FontFamily.Outfit};
      white-space: nowrap;
    }
  `}
`;
