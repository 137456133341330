import React, {useCallback} from 'react';
import {Box, Button, Div, TitledText} from '@/Website/Components/UI';
import {dateToLocalDate} from '@/Services/date.service';
import useCloseTalent from '../../Hooks/useCloseTalent';
import {useDeleteTalents} from '@/Website/Pages/Dashboard/Talents';
import {usePortalsStore} from '@/Store/portals/portals.selector';

interface Props {
  about: {email: string; dateJoined?: string};
}
const TalentAbout: React.FC<Props> = props => {
  const {about} = props;
  const closeTalent = useCloseTalent();
  const {talent} = usePortalsStore().selectors.talentPortal;
  const deleteTalent = useDeleteTalents();

  const handleRemoveRequest = useCallback(async () => {
    if (talent) {
      await deleteTalent([talent.talentId]).then(() => closeTalent());
    }
  }, [talent]);

  return (
    <Box backgroundColor="Lime" label="About">
      <Div flexDirection="column" gap={20}>
        <TitledText
          style={{flexDirection: 'column'}}
          title={{type: 'Text2', fontWeight: 800, value: 'Email'}}
          text={{value: about.email, type: 'Text3', color: 'Orange'}}
        />
        <TitledText
          style={{flexDirection: 'column'}}
          title={{type: 'Text2', fontWeight: 800, value: 'Joined'}}
          text={{value: dateToLocalDate(about.dateJoined), type: 'Text3', color: 'Blue'}}
        />
        <Button onClick={handleRemoveRequest} size="small" color="Alert">
          Remove request
        </Button>
      </Div>
    </Box>
  );
};

export default TalentAbout;
