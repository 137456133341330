import {Button, Curtain, Div, Input, InputFile, PhoneForm, Text} from '@/Website/Components/UI';
import {Field, Form, Formik} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import useCreateTalent from '../Hooks/useCreateTalent';
import useUploadRoster from '../Hooks/useUploadRoster';
import {NewTalentService} from './newTalent.service';

interface Props {
  onClose: Function;
  afterCreate: Function;
}
const NewTalent: React.FC<Props> = ({afterCreate, onClose}) => {
  const createCallBack = useCallback(() => {
    afterCreate();
    onClose();
  }, []);
  const createTalent = useCreateTalent(createCallBack);
  const uploadRoster = useUploadRoster(onClose);

  const {initialValues, validationSchema} = useMemo(() => new NewTalentService(), []);
  const [rosterFile, setRosterFile] = useState<File[]>([]);

  return (
    <Curtain
      headLineProps={{onClose, title: 'Create new talent'}}
      curtainConfig={{
        slideFrom: 'left',
        width: '430px',
        disableClickOut: true,
        height: '100vh',
        right: '0px',
        top: '0px',
      }}
    >
      <Div flexDirection="column" gap={10}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={createTalent}>
          {({errors, touched}) => (
            <Form>
              <Div flexDirection="column" gap={10}>
                <Field name="email" placeholder="Email" as={Input} Error={touched.email && errors.email} />
                <Field
                  name="password"
                  placeholder="Password"
                  as={Input}
                  Error={touched.password && errors.password}
                />
                <Field
                  name="username"
                  placeholder="Username"
                  as={Input}
                  Error={touched.username && errors.username}
                />
                <Field
                  name="fullName"
                  placeholder="Fullname"
                  as={Input}
                  Error={touched.fullName && errors.fullName}
                />
                <PhoneForm name="phone" />
                <Button isDisabled={Object.keys(errors).length > 0} color="DarkBlue" type="submit">
                  CREATE
                </Button>
              </Div>
            </Form>
          )}
        </Formik>

        <Div margin="50px 0 0 0" flexDirection="column">
          <Text type="Text3" italic>
            You can upload excel file
          </Text>
          <InputFile label="Choose excel file" onUpload={setRosterFile} allowedExtensions="excel" />
          <Button
            onClick={async () => rosterFile?.length && (await uploadRoster(rosterFile[0]))}
            size="small"
            isDisabled={!rosterFile?.length}
            color="Orange"
          >
            UPLOAD
          </Button>
        </Div>
      </Div>
    </Curtain>
  );
};
export default React.memo(NewTalent);
