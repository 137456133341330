import React, {memo, useMemo} from 'react';
import {SocialService} from '@/Services/social.service';
import {STalentTab} from '@/Website/Components/Portals/Talent/talent.styled';
import {
  ElementHeadline,
  Label,
  SocialIcon,
  TableContainer,
  TCell,
  Text,
  Tooltip,
  TRow,
} from '@/Website/Components/UI';
import {useOrganizationOrders} from '@/Website/Pages/Dashboard/Orders';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useOpenOrder} from '@/Website/Components/Portals/Order';
import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';

const TalentOrders = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  const params = useMemo(() => ({talentId: talent?.talentId}), [talent]);
  const tableHeaders = useMemo(
    () => ['ID', '', 'Status', 'Campaign', 'Price', 'Adv price', 'Created at'],
    []
  );
  const {orders, isLoading} = useOrganizationOrders(params);
  const openOrder = useOpenOrder();
  return (
    <STalentTab>
      <ElementHeadline disableMarker title={`Total orders: `} />
      <TableContainer isSmall headers={tableHeaders} stickyHeader isLoading={isLoading}>
        {orders.orders.map((order, i) => (
          <TRow
            key={i}
            onClick={e => {
              e.stopPropagation();
              openOrder(order);
            }}
          >
            <TCell>
              <Text type="Text3">{order.id}</Text>
            </TCell>
            <TCell>
              <Tooltip title={order.talentInfo.url}>
                <SocialIcon
                  onClick={e => {
                    e.stopPropagation();
                    void SocialService.handlePlatformClick(order.talentInfo.url);
                  }}
                  isActive
                  social={order.talentInfo.socialPlatform}
                  size="medium"
                />
              </Tooltip>
            </TCell>
            <TCell>
              <Label backgroundColor={ColorService.getColorTypeByStatus(order.status)}>{order.status}</Label>
            </TCell>
            <TCell>
              <Text color="Purple" type="Text3">
                {order.campaign.id + ':' + order.campaign.title}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" fontWeight={800} color="Orange">
                {order.talentPrice || 0}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" fontWeight={800} color="Alert">
                {order.advertiserPrice || 0}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success">
                {dateToLocalDate(order.createdAt)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </STalentTab>
  );
};
export default memo(TalentOrders);
