import React, {useState} from 'react';
import {Button, Div, InputFile, Text} from '@/Website/Components/UI';
import {useUploadOrderMaterial} from '@/Website/Components/Portals/Order';

interface Props {
  onUploading?: Function;
}
const OrderUploadingFile: React.FC<Props> = props => {
  const [uploadedFile, setUploadedFile] = useState<File[]>();
  const upload = useUploadOrderMaterial(props.onUploading);

  return (
    <Div gap={10} flexDirection="column" margin="15px 0 0 0">
      <Text type="Text3">
        To upload media file: click on <strong>Select file</strong>, choose you file and click
        <strong> upload</strong>
      </Text>
      <Div justifyContent="space-between" alignItems="flex-start">
        <InputFile allowedExtensions="video" label="Select file" onUpload={setUploadedFile} />
        <Button
          onClick={async () => uploadedFile && (await upload(uploadedFile[0]))}
          color="Success"
          size="small"
          isDisabled={!uploadedFile}
        >
          UPLOAD
        </Button>
      </Div>
    </Div>
  );
};
export default OrderUploadingFile;
