import React, {memo, useCallback, useState} from 'react';
import {Button, Div, ElementHeadline, InputFile, Modal} from '@/Website/Components/UI';
import {useUploadTalentFinancialDoc} from '@/Website/Components/Portals/Talent';

interface Props {
  afterUpload: Function;
  onClose: Function;
}
const UploadTalentFinancialDoc: React.FC<Props> = props => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const uploadFile = useUploadTalentFinancialDoc();
  const {onClose, afterUpload} = props;
  const handleUpload = useCallback(
    async () =>
      await uploadFile(filesToUpload[0]).then(() => {
        afterUpload();
        onClose();
      }),
    [filesToUpload]
  );
  return (
    <Modal isShowModal={true}>
      <Div width={400} flexDirection="column" gap={20}>
        <ElementHeadline title="Upload doc" disableMarker onClose={onClose} />
        <InputFile allowedExtensions="doc" label="Select file" onUpload={setFilesToUpload} />
        <Button
          onClick={async () => await handleUpload()}
          color="Success"
          size="medium"
          isDisabled={filesToUpload.length === 0}
        >
          SUBMIT
        </Button>
      </Div>
    </Modal>
  );
};
export default memo(UploadTalentFinancialDoc);
