import {useEffect, useMemo, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useRootStore} from '@/Store/root/root.selector';
import {AgenciesCampaignsResponse} from '@/API/Agency/agency.types';
import {SelectOptionsType} from '@/Website/Components/UI/Select/select.styled';

const useOrganizationCampaigns = () => {
  const [campaignsResponse, setCampaignsResponse] = useState<AgenciesCampaignsResponse>({
    campaigns: [],
    count: 0,
  });
  const {user} = useRootStore().selectors;
  const fetch = useApiLoader(
    async () => {
      if (user?.id) {
        return await API.Agencies.getAgencyCampaigns(user.id).then(res => setCampaignsResponse(res));
      }
    },
    [],
    {disableLoader: true}
  );
  useEffect(() => void fetch(), [fetch]);
  const campaignSelectOptions = useMemo(() => {
    let options: SelectOptionsType = [{label: '-', value: ''}];
    if (campaignsResponse.campaigns && campaignsResponse?.campaigns?.length > 0) {
      options.push(...campaignsResponse.campaigns.map(el => ({value: el.id, label: el.id + ':' + el.title})));
    }
    return options;
  }, [campaignsResponse]);
  return {
    campaigns: campaignsResponse,
    campaignSelectOptions,
  };
};
export default useOrganizationCampaigns;
