import {ValidationValues} from '@/Util/types';
import * as Yup from 'yup';

export class NewTalentService {
  readonly validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
      .matches(/[a-z]/, 'Must not contain uppercase letters'),
    password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
    phone: Yup.string().required('Required'),
    username: Yup.string()
      .min(4, 'Must be at least 4 characters')
      .max(20, 'Must be at most 20 characters')
      .required('Required')
      .matches(/^[a-zA-Z0-9_]+$/, 'Must contain only letters, numbers, or underscores'),
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Must contain only letters and spaces')
      .required('Required'),
  });

  readonly initialValues: ValidationValues<typeof this.validationSchema> = {
    email: '',
    password: '',
    phone: '',
    username: '',
    fullName: '',
  };
}
