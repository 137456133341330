import styled, {css} from 'styled-components';

export const Component = styled.div<{isOpen: boolean; isASC: boolean}>`
  ${({theme, isOpen, isASC}) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    gap: 5px;
    width: max-content;
    padding: 0px 5px;
    cursor: pointer;
    .options {
      max-height: ${isOpen ? `300px` : `0px`};
      display: flex;
      position: absolute;
      z-index: 99;
      background-color: white;
      top: 0;
      right: 5px;
      margin-top: 30px;
      flex-direction: column;
      align-items: stretch;
      width: max-content;
      overflow: hidden;
      box-shadow: 1px 1px 1px ${theme.Color.GrayLight};
      transition: max-height 0.3s;
      ${isOpen &&
      css`
        border: 1px solid ${theme.Color.GrayLight};
        border-radius: 15px;
      `}

      .option {
        font-family: ${theme.FontFamily.Outfit};
        font-size: 0.7rem;
        padding: 5px 25px;
        border-radius: 15px;
        white-space: nowrap;
        &:hover {
          background-color: #e8e8e8;

          cursor: pointer;
        }
      }
      .selected {
        background-color: ${theme.Color.Success};
        color: ${theme.Color.White};
        font-weight: 800;
        &:hover {
          background-color: ${theme.Color.Success};
          color: ${theme.Color.White};
        }
      }
    }
    svg {
      height: 21px;
      width: 21px;
      fill: ${theme.Color.Success};
      transition: transform 0.3s ease;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .arrow-icon {
      ${isOpen &&
      css`
        transform: rotate(180deg);
      `}
    }
    .sort-icon {
      ${!isASC &&
      css`
        transform: rotate(180deg);
      `}
    }
  `}
`;
