import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {useRootStore} from '@/Store/root/root.selector';
import {UserFirebaseCredentials} from '@/Hooks/Auth/types';
import {StorageService} from '@/Services/storage.service';
import {initializeApp} from 'firebase/app';
import {firebaseConfig} from '@/Config/firebase.config';
import useApiLoader from '../useApiLoader';
import useSetAuth from './useSetAuth';

const useFirebaseLogin = () => {
  const FirebaseApplication = initializeApp(firebaseConfig);
  const authentication = getAuth(FirebaseApplication);
  const {setNotification} = useRootStore().actions;
  const setAuth = useSetAuth();
  return useApiLoader(
    async (credentials: {email: string; password: string}) => {
      await signInWithEmailAndPassword(authentication, credentials.email, credentials.password)
        .then((authResponse: any) => {
          const tokenManager: UserFirebaseCredentials['user']['stsTokenManager'] =
            authResponse.user.stsTokenManager;
          StorageService.set('TOKEN', tokenManager.accessToken);
        })
        .then(async () => await setAuth())

        .catch(() => {
          setNotification({message: 'Invalid permissions', type: 'failed'});
        });
    },
    [authentication]
  );
};
export default useFirebaseLogin;
