import {TalentsSearchParams} from '@/API/Talents/talents.types';

const TalentsService = {
  defaultTableFilterState: {
    skip: 0,
    limit: 30,
  } as Omit<TalentsSearchParams, 'organizationId'>,
  tableHeaders: ['', 'Name', 'Country', 'Bio', ''],
};
export default TalentsService;
