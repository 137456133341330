import React from 'react';
import styled, {css} from 'styled-components';
import {Text} from '@/Website/Components/UI';

const Component = styled.div`
  ${({theme}) => css`
    padding: 4px 0;
    .text-area-label {
      color: #a1a1a1;
      font-size: 0.6rem;
    }
    .text-area {
      margin-top: 3px;
      ${theme.Presets.scroll}
      font-size: 0.7rem;

      font-family: ${theme.FontFamily.Outfit};
      border: none;
      border-radius: 15px;
      color: #000000;
      background-color: #f9f9f9;
      outline: 1px solid #e7e6e6;
      padding: 10px;
      resize: none;
      width: 100%;
      &:focus {
        outline-color: #f2c5fc;
      }
    }
  `}
`;
interface TextAreaProps {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  placeholder?: string;
  row?: number;
}
const TextArea: React.FC<TextAreaProps> = ({onChange, value, placeholder: placeHolder, row}) => {
  return (
    <Component className="TextArea">
      <Text color="MediumGray" className="text-area-label">
        {placeHolder}
      </Text>
      <textarea value={value} onChange={e => onChange(e)} className="text-area" rows={row} />
    </Component>
  );
};

export default TextArea;
