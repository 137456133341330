import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {UpdateOrderPricePayload} from '@/API/AgencyTalents/agencyTalents.types';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useUpdateOrderPrice = () => {
  const {orderPortalCallBack} = usePortalsStore().selectors.orderPortal;
  return useApiLoader(
    async (payload: UpdateOrderPricePayload) => {
      return await API.Orders.updateOrderPrice(payload).then(
        () => orderPortalCallBack && orderPortalCallBack()
      );
    },
    [],
    {successMsg: 'Price updated'}
  );
};
export default useUpdateOrderPrice;
