import {Button, Div, ElementHeadline, Modal} from '@/Website/Components/UI';
import {useRootStore} from '@/Store/root/root.selector';
import styled from 'styled-components';
import MediaService from '@/Services/media.service';
import {useCallback} from 'react';

const SMediaPlayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .video-media-player,
  .image-media-player {
    position: relative;
    width: 700px;
    height: 500px;
    margin: 5px;
  }
`;
const MediaPlayer = () => {
  const {closeMediaPlayer} = useRootStore().actions;
  const {mediaPath, isShowMediaPlayer} = useRootStore().selectors.mediaPlayer;
  const downloadClick = useCallback(() => window.open(mediaPath, '_blank'), [mediaPath]);
  return (
    <Modal isShowModal={isShowMediaPlayer} onClose={closeMediaPlayer}>
      <ElementHeadline title={mediaPath || ''} fontSize="Text3" disableMarker />
      <SMediaPlayer className="MediaPlayer">
        {MediaService.isVideo(mediaPath) ? (
          <video className="video-media-player" src={mediaPath} autoPlay controls></video>
        ) : (
          <img alt="" src={mediaPath} className="image-media-player" />
        )}
      </SMediaPlayer>
      <Button onClick={downloadClick} color="BlackOcean" size="medium">
        DOWNLOAD
      </Button>
    </Modal>
  );
};
export default MediaPlayer;
