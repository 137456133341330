import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React from 'react';
import SPhone from './phone.styled';
import {useField} from 'formik';

interface PhoneInputFieldProps {
  name: string;
}

const PhoneForm: React.FC<PhoneInputFieldProps> = ({name}) => {
  const [field, meta, helpers] = useField(name);

  const handleOnChange = (e: string) => {
    helpers.setValue(e ? `+${e}` : e);
  };

  return (
    <SPhone>
      <div className="phone-label">Phone</div>
      <PhoneInput
        prefix="+"
        enableSearch
        placeholder="Phone"
        countryCodeEditable={false}
        country={'us'}
        value={field.value}
        onChange={handleOnChange}
        onBlur={field.onBlur}
      />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </SPhone>
  );
};

export default PhoneForm;
