import React from 'react';
import TalentAbout from '@/Website/Components/Portals/Talent/TalentDetails/TalentAbout/TalentAbout';
import {Div} from '@/Website/Components/UI';
import TalentInfo from '@/Website/Components/Portals/Talent/TalentDetails/TalentInfo/TalentInfo';
import {TalentUpdatable} from '@/Website/Components/Portals/Talent/talent.service';
import {STalentTab} from '@/Website/Components/Portals/Talent/talent.styled';

interface Props extends TalentUpdatable {
  user: {
    email: string;
    dateJoined: string;
  };
}
const TalentDetails: React.FC<Props> = ({talentUpdate, setTalentUpdate, user}) => {
  return (
    <STalentTab>
      <Div height="100%" gap={10}>
        <TalentAbout about={{email: user.email, dateJoined: user.dateJoined}} />
        <TalentInfo talentUpdate={talentUpdate} setTalentUpdate={setTalentUpdate} />
      </Div>
    </STalentTab>
  );
};
export default TalentDetails;
