import {useEffect, useState} from 'react';
import {TalentDetailed, TalentUpdateDTO} from '@/API/Talents/talents.types';
import {useApiLoader} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {API} from '@/API/API';

const useUpdateTalent = (talent?: TalentDetailed) => {
  const [talentToUpdate, setTalentToUpdate] = useState<TalentUpdateDTO>();
  const {talentPortalCallBack} = usePortalsStore().selectors.talentPortal;

  const updateTalent = useApiLoader(
    async () => {
      if (talentToUpdate && talent) {
        await API.Talents.updateTalent(talentToUpdate, talent?.cognitoId).then(
          async () => talentPortalCallBack && (await talentPortalCallBack())
        );
      }
    },
    [talent, talentToUpdate],
    {successMsg: 'Talent updated'}
  );

  useEffect(() => {
    if (talent && !talentToUpdate) {
      setTalentToUpdate({
        categories: undefined,
        profile: {
          avatar: talent.talentProfile.avatar,
          avatarVideo: talent.talentProfile.avatarVideo,
          bio: talent.talentProfile.bio,
          creationPlatforms: talent.talentProfile.creationPlatforms,
          discord: talent.talentProfile.discord,
          facebook: talent.talentProfile.facebook,
          firebaseNotificationsExpiration: talent.talentProfile.firebaseNotificationsExpiration,
          firebaseNotificationsId: talent.talentProfile.firebaseNotificationsId,
          hashtags: talent.talentProfile.hashtags,
          instagram: talent.talentProfile.instagram,
          linkedin: talent.talentProfile.linkedin,
          notes: talent.talentProfile.notes,
          providerId: talent.talentProfile.providerId,
          shortBio: talent.talentProfile.shortBio,
          tiktok: talent.talentProfile.tiktok,
          twitch: talent.talentProfile.twitch,
          twitter: talent.talentProfile.twitter,
          youtube: talent.talentProfile.youtube,
        },
        user: {
          country: talent.country,
          languages: talent.languages,
          signupPlatform: talent.signupPlatform,
          // username: talent.username,
          fullName: talent.fullName,
          phoneNumber: talent.phoneNumber,
          birthDate: talent.birthDate,
        },
      });
    }
  }, [talent]);
  return {
    talentToUpdate,
    setTalentToUpdate,
    updateTalent,
  };
};
export default useUpdateTalent;
