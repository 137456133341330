import {useApiLoader} from '@/Hooks';
import {TalentUpdateMediaDTO} from '@/API/Talents/talents.types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {S3Service} from '@/Services/s3.service';
import {API} from '@/API/API';

const useDeleteTalentMedia = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  return useApiLoader(
    async (payload: {filePath?: string; mediaType: TalentUpdateMediaDTO['mediaType']}) => {
      if (talent && payload.filePath) {
        const {filePath, mediaType} = payload;

        await API.Talents.updateTalentMedia({
          mediaType,
          link: '',
          talentId: talent.talentId,
        }).then(async () => {
          await S3Service.deleteFileFromS3(filePath, false);
        });
      }
    },
    [],
    {successMsg: 'Successfully deleted'}
  );
};
export default useDeleteTalentMedia;
