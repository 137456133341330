import React from 'react';
import {Grid, Input} from '@/Website/Components/UI';
import {TalentsSearchParams} from '@/API/Talents/talents.types';

interface Props {
  filters: Omit<TalentsSearchParams, 'organizationId'>;
  setFilters: (prop: keyof Omit<TalentsSearchParams, 'organizationId'>, value: any) => void;
}
const TalentsFilters: React.FC<Props> = ({filters, setFilters}) => {
  return (
    <Grid templateColumn="repeat(2,250px)" columnGap={20}>
      <Input
        placeholder="Name / Email / Username"
        searchIcon
        value={filters.searchValue}
        onChange={e => setFilters('searchValue', e.target.value)}
      />
    </Grid>
  );
};
export default TalentsFilters;
