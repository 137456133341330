import {useBooleanState} from '@/Hooks/useBooleanState';
import {useTalentFinancialDocs} from '@/Website/Components/Portals/Talent';
import {STalentTab} from '@/Website/Components/Portals/Talent/talent.styled';
import {ElementHeadline} from '@/Website/Components/UI';
import {memo} from 'react';
import TalentFinancialDocsTable from './TalentFinancialDocsTable';
import UploadTalentFinancialDoc from './UploadTalentFinancialDoc';

const TalentFinancialDocs = () => {
  const {financialDocsResponse, reloadDocs, isLoading} = useTalentFinancialDocs();
  const ShowFinancialDocUpload = useBooleanState();

  return (
    <STalentTab>
      <ElementHeadline
        disableMarker
        title={`Total docs: ${financialDocsResponse.count}`}
        onCreateNew={ShowFinancialDocUpload.turnOn}
        onCreateNewMsg="Upload document"
      />
      <TalentFinancialDocsTable
        afterDelete={reloadDocs}
        isLoading={isLoading}
        docs={financialDocsResponse.docs}
      />
      {ShowFinancialDocUpload.isTurn && (
        <UploadTalentFinancialDoc afterUpload={reloadDocs} onClose={ShowFinancialDocUpload.turnOff} />
      )}
    </STalentTab>
  );
};
export default memo(TalentFinancialDocs);
