import {OrganizationOrdersSearchParams} from '@/API/Orders/orders.types';

const OrdersService = {
  ordersTableHeaders: ['ID', '', 'Status', 'Talent', 'Campaign', 'Price', 'Adv price', 'Created at'],
  defaultTableFilterState: {
    limit: 20,
    skip: 0,
  } as Omit<OrganizationOrdersSearchParams, 'organizationId'>,
  orderStatuses: {
    offered: ['campaign-full', 'campaign-ended', 'not-responsive'],
    rejected: ['price', 'content', 'delivery-due-date', 'not-responsive'],
    'waiting-list': [
      'waiting-for-available-spot',
      'pending-review by-brand',
      'pending-review-by-ops',
      'price-negotiation',
    ],
    'approved-for-campaign': ['pending-to-receive-video', 'not-responsive'],
    'campaign-full': ['late-submission', 'not-matching', 'rejected-by-brand', 'rejected-by-ops'],

    submitted: ['pending-review-by-brand', 'pending-review-by-Ops', 'incorrect-file', 'not-responsive'],
    'approved-video': ['not-responsive'],
    revision: ['1st-revision', '2nd-revision', '3rd-revision', 'not-responsive'],

    posted: ['link-not-visible', 'missing-tags-or-hashtags', 'missing-description'],
    tracking: [
      'missing-live-video-after-7-days',
      'missing-live-video-after-14-days',
      'missing-live-video-after-21-days',
      'not-responsive',
    ],
    completed: ['30-days-campaign-tracking-over', 'payment-received'],
    'campaign-ended': ['successful-tracking', 'no-participants', 'rejected-offer', 'tracking-not-completed'],
    dispute: [
      'video-content-not-eligible',
      'more-than-3-revisions',
      'resolved',
      'not-responsive',
      'not-able-to-follow-guidelines',
    ],
    'approved-after-revision': [],
    ended: [],
  },
};

export type OrderStatusType = keyof typeof OrdersService.orderStatuses;
export default OrdersService;
