import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {OrderDetailed} from '@/API/Orders/orders.types';

const useGetOrder = () => {
  const [order, setOrder] = useState<OrderDetailed>();
  const {orderPortal} = usePortalsStore().selectors;
  const Loading = useBooleanState();
  const fetch = useApiLoader(
    async () => {
      if (orderPortal.order) {
        Loading.turnOn();
        await API.Orders.getOrderByTalentId(orderPortal.order.talent, orderPortal.order.id)
          .then(res => setOrder(res))
          .finally(() => Loading.turnOff());
      }
    },
    [orderPortal],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    order,
    reloadOrder: fetch,
    isLoading: Loading.isTurn,
  };
};
export default useGetOrder;
