import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useRootStore} from '@/Store/root/root.selector';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {TalentSearchResponse, TalentsSearchParams} from '@/API/Talents/talents.types';

const useTalents = (params?: Omit<TalentsSearchParams, 'organizationId'>) => {
  const {user} = useRootStore().selectors;
  const [talentsResponse, setTalentsResponse] = useState<TalentSearchResponse>({count: 0, talents: []});
  const Loader = useBooleanState();
  const fetch = useApiLoader(
    async () => {
      if (user?.id && params) {
        Loader.turnOn();
        let toSearch: TalentsSearchParams = {
          organizationId: user.id,
        };
        if (params.searchValue) toSearch.searchValue = params.searchValue;
        if (params.skip) toSearch.skip = params.skip;
        if (params.limit) toSearch.limit = params.limit;

        await API.Talents.getTalentsByOrganization(toSearch)
          .then(res => setTalentsResponse(res))
          .finally(() => Loader.turnOff());
      }
    },
    [params],
    {disableLoader: true}
  );
  useEffect(() => void fetch(), [fetch]);
  return {
    talentsResponse,
    isLoading: Loader.isTurn,
    reloadTalents: fetch,
  };
};
export default useTalents;
