import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {CONFIG_ENV} from '@/Config/config.env';
import {CONTROLLERS} from '../CONTROLLERS';
import {Organization} from './organization.types';

class OrganizationsApi {
  readonly api: AxiosInstance = new FactoryAxios(
    CONFIG_ENV('ORGANIZATION_MS'),
    CONTROLLERS.Organizations
  ).getInstance();

  async getOwnOrganization(): Promise<Organization> {
    return await this.api.get('own').then(res => {
      return res.data[0];
    });
  }
}

export default OrganizationsApi;
