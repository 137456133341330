import React, {useEffect, memo, useCallback} from 'react';
import {useRootStore} from '@/Store/root/root.selector';
import {NotificationType} from '@/Store/root/types';
import {Component} from './notification.styled';
import {Box, Div, Text} from '@/Website/Components/UI';
import {ReactComponent as SuccessIcon} from '@/Assets/Icons/success.svg';
import {ReactComponent as WarningIcon} from '@/Assets/Icons/warning.svg';
const Notification: React.FC = () => {
  const {
    selectors: {notification},
    actions: {setShowNotification},
  } = useRootStore();
  const turnOffNotify = useCallback(() => setShowNotification(false), []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      turnOffNotify();
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, [notification.isShowNotification]);

  const isFailed = notification.type === 'failed';

  return (
    <Component
      onClick={turnOffNotify}
      className="Notification"
      Type={notification.type as NotificationType['type']}
    >
      <Box className="notify">
        <div className="notify-header">
          <Div gap={5} alignItems="center">
            {isFailed ? <WarningIcon className="warningIcon" /> : <SuccessIcon className="successIcon" />}
            <Text color={isFailed ? 'Alert' : 'Success'} type="Text1" fontWeight={600}>
              {isFailed ? 'Error' : 'Notification'}
            </Text>
          </Div>
        </div>
        <Box className="notify-body" flexDirection="column">
          <Text type="Text3" fontWeight={600}>
            {notification?.message?.toUpperCase()}
          </Text>
        </Box>
        <span className="progress"></span>
      </Box>
    </Component>
  );
};
export default memo(Notification);
