import React, {useCallback} from 'react';
import {Box, Input, Phone, TextArea} from '@/Website/Components/UI';
import {TalentUpdateDTO} from '@/API/Talents/talents.types';
import {GenericSelect} from '@/Website/Components/Common';
import {dateInputAdapter} from '@/Services/date.service';
import {TalentUpdatable} from '@/Website/Components/Portals/Talent/talent.service';
import TalentMediaFiles from './TalentMediaFiles';

const TalentInfo: React.FC<TalentUpdatable> = props => {
  const {setTalentUpdate, talentUpdate} = props;
  const handleUserPropChange = useCallback(
    (key: keyof TalentUpdateDTO['user'], value: any) => {
      setTalentUpdate(prev => ({...prev, user: {...prev.user, [key]: value}}));
    },
    [setTalentUpdate]
  );
  const handleProfilePropChange = useCallback(
    (key: keyof TalentUpdateDTO['profile'], value: any) => {
      setTalentUpdate(prev => ({...prev, profile: {...prev.profile, [key]: value}}));
    },
    [setTalentUpdate]
  );

  return (
    <>
      <Box backgroundColor="AliceBlue" flexDirection="column" label="Info">
        <Input
          placeholder="Full name"
          value={talentUpdate.user.fullName}
          onChange={e => handleUserPropChange('fullName', e.target.value)}
        />
        <Phone
          value={talentUpdate.user.phoneNumber}
          onChange={value => handleUserPropChange('phoneNumber', value)}
        />
        <GenericSelect
          value={talentUpdate.user.country}
          onChange={value => handleUserPropChange('country', value)}
          optionsType="countries"
          label="Country"
          defaultPlaceholder={talentUpdate.user.country}
        />

        <Input
          placeholder="Birthdate"
          value={dateInputAdapter(talentUpdate.user.birthDate)}
          type={'date'}
          onChange={e => handleUserPropChange('birthDate', e.target.value)}
        />
      </Box>
      <Box backgroundColor="AliceBlue" label="Bio" flexDirection="column">
        <TextArea
          value={talentUpdate.profile.shortBio || ''}
          onChange={e => handleProfilePropChange('shortBio', e.target.value)}
          placeholder="Short bio"
          row={8}
        />
        <TextArea
          value={talentUpdate.profile.bio || ''}
          onChange={e => handleProfilePropChange('bio', e.target.value)}
          placeholder="Bio"
          row={17}
        />
      </Box>
      <TalentMediaFiles setTalentUpdate={setTalentUpdate} talentUpdate={talentUpdate} />
    </>
  );
};
export default TalentInfo;
