import {Route, Routes} from 'react-router-dom';
import React, {memo, useMemo} from 'react';
import {useRootStore} from '@/Store/root/root.selector';
import Login from '@/Website/Pages/Login/Login';
import {ROUTES} from './ROUTES';
import Layout from '@/Website/Layout/Layout';
import {RoutesValues} from '@/Router/router.service';
import {useFirebaseInit} from '@/Hooks';

const Router: React.FC = () => {
  const {isAuth} = useRootStore().selectors;
  const routes = useMemo(() => RoutesValues, []);
  useFirebaseInit();

  return isAuth ? (
    <Routes>
      <Route path={ROUTES.default} element={<Layout />}>
        {routes.map(({Element, path, index}, i) => (
          <Route path={path} element={<Element />} index={index} key={i} />
        ))}
      </Route>
      <Route path={'*'} element={<Login />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={'*'} element={<Login />} />
    </Routes>
  );
};

export default memo(Router);
