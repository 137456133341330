import React, {useMemo} from 'react';
import {Div, Text} from '@/Website/Components/UI';
import {OrderService} from '@/Website/Components/Portals/Order';

import {
  OrderLinkPostUpdater,
  OrderPassage,
  OrderRevision,
  OrderUploadingFile,
} from '@/Website/Components/Portals/Order/OrderResponse/index';
import {OrderDetailed} from '@/API/Orders/orders.types';

interface Props {
  order?: OrderDetailed;
  onPassageCallback?: Function;
}

const OrderResponse: React.FC<Props> = props => {
  const {onPassageCallback, order} = props;

  const textToDisplay = useMemo(() => OrderService.getOrderResponseTextByOrderStatus(order?.status), [order]);
  const revision = useMemo(() => {
    if (order?.orderRevisions && order?.orderRevisions.length) {
      return {reason: order.orderRevisions[0].reason, changes: order.orderRevisions[0].changes};
    }
  }, [order]);

  const isShowOrderPassage = order?.status === 'offered' && order?.talent;
  const isShowRevisionText = order?.status === 'revision' && revision;
  const isShowOrderUploadingFile = order?.status === 'revision' || order?.status === 'approved-for-campaign';
  const isShowOrderStatus = order?.status === 'approved-video';

  return (
    <Div flexDirection="column" gap={5}>
      {textToDisplay && (
        <Text textAlign="center" type="Text1">
          {textToDisplay}
        </Text>
      )}
      {isShowRevisionText && <OrderRevision revision={revision} />}
      {isShowOrderPassage && order?.talent && <OrderPassage onPassageCallback={onPassageCallback} />}
      {isShowOrderUploadingFile && <OrderUploadingFile onUploading={onPassageCallback} />}
      {isShowOrderStatus && <OrderLinkPostUpdater afterLinkUpdated={onPassageCallback} />}
    </Div>
  );
};
export default OrderResponse;
