import React, {useMemo} from 'react';

import {SocialService} from '@/Services/social.service';
import {SelectProps} from '@/Website/Components/UI/Select/Select';
import {OrdersService} from '@/Website/Pages/Dashboard/Orders';
import {Select} from '@/Website/Components/UI';
import {SelectService} from '@/Services/select.service';
import {UTIL_CONSTANTS} from '@/Util/constants';

type Props = Omit<SelectProps, 'options'> & {
  optionsType: 'orderStatuses' | 'socials' | 'countries';
};

const GenericSelect: React.FC<Props> = ({optionsType, ...props}) => {
  const options = useMemo(() => {
    switch (optionsType) {
      case 'orderStatuses':
        return SelectService.convertObjectKeysToSelectOption(OrdersService.orderStatuses);

      case 'socials':
        return SocialService.SOCIALS_SELECT_OPTIONS;

      case 'countries':
        return UTIL_CONSTANTS.COUNTRIES;
    }
  }, []);
  return options ? <Select options={[{value: '', label: '-'}, ...options]} {...props} /> : null;
};
export default GenericSelect;
