import React, {memo, useCallback, useEffect, useRef} from 'react';
import SScrollUp from './scrollUp.styled';
import {ReactComponent as ArrowUpIcon} from '@/Assets/Icons/arrow_up.svg';

const ScrollUp: React.FC = () => {
  const ref = useRef<any>(null);
  const handleClick = useCallback(() => {
    ref.current.parentNode.scrollTo({top: 0, behavior: 'smooth'});
  }, [ref]);
  useEffect(() => {
    const parent = ref.current.parentNode;
    const log = (e: any) => {
      if (e.target.scrollTop > 200) ref.current.style.display = 'flex';
      else ref.current.style.display = 'none';
    };

    parent.addEventListener('scroll', log);
    return () => parent.removeEventListener('scroll', log);
  }, []);
  return (
    <SScrollUp className="ScrollUp" ref={ref}>
      <ArrowUpIcon onClick={handleClick} />
    </SScrollUp>
  );
};
export default memo(ScrollUp);
