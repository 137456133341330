import {useRootStore} from '@/Store/root/root.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CreateTalentDto} from '@/API/Talents/talents.types';

const useCreateTalent = (callBack?: Function) => {
  const {user} = useRootStore().selectors;
  return useApiLoader(
    async (payload: Omit<CreateTalentDto, 'organizationID'>) => {
      if (user) {
        return await API.Talents.createAgencyTalent({...payload, organizationID: user.id});
      }
    },
    [callBack, user],
    {successMsg: 'Talent created'}
  );
};
export default useCreateTalent;
