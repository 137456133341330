import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {TalentDetailed} from '@/API/Talents/talents.types';

const useTalent = () => {
  const [talent, setTalent] = useState<TalentDetailed>();
  const talentId = usePortalsStore().selectors.talentPortal.talent?.talentId;
  const fetch = useApiLoader(async () => {
    if (talentId) {
      await API.Talents.getTalentById(talentId).then(res => setTalent(res));
    }
  }, [talentId]);
  useEffect(() => {
    void fetch();
  }, []);
  return {
    talent,
    setTalent,
    reloadTalent: fetch,
  };
};

export default useTalent;
