import {CONFIG_ENV} from '@/Config/config.env';
import {DeleteObjectOutput, PutObjectOutput} from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';

export class S3Service {
  static getS3(secure?: boolean) {
    const config = {
      accessKeyId: secure ? CONFIG_ENV('AWS_ACCESS_KEY_ID_SECURE') : CONFIG_ENV('AWS_ACCESS_KEY_ID'),
      secretAccessKey: secure
        ? CONFIG_ENV('AWS_SECRET_ACCESS_KEY_SECURE')
        : CONFIG_ENV('AWS_SECRET_ACCESS_KEY'),
      region: CONFIG_ENV('AWS_REGION'),
    };
    AWS.config.update(config);
    return new AWS.S3();
  }
  static uploadFileToS3(payload: {file: File; key: string; secure?: boolean}): Promise<PutObjectOutput> {
    const {file, key, secure} = payload;

    const s3 = this.getS3(secure);
    const params = {
      Bucket: secure ? CONFIG_ENV('AWS_BUCKET_SECURE') : CONFIG_ENV('AWS_BUCKET'),
      Key: key,
      Body: file,
      ACL: 'public-read',
    };

    return new Promise<PutObjectOutput>((resolve, reject) => {
      s3.putObject(params, (err: any, data: PutObjectOutput) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
  static deleteFileFromS3(fileName: string, secure?: boolean): Promise<DeleteObjectOutput> {
    const s3 = this.getS3(secure);
    const params = {
      Bucket: secure ? CONFIG_ENV('AWS_BUCKET_SECURE') : CONFIG_ENV('AWS_BUCKET'),
      Key: fileName,
    };

    return new Promise<DeleteObjectOutput>((resolve, reject) => {
      s3.deleteObject(params, (err: any, data: DeleteObjectOutput) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
}
