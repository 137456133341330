import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {FinancialDocsResponse} from '@/API/Talents/talents.types';

const useTalentFinancialDocs = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  const [financialDocsResponse, setFinancialDocsResponse] = useState<FinancialDocsResponse>({
    count: 0,
    docs: [],
  });
  const Loader = useBooleanState();

  const fetch = useApiLoader(
    async () => {
      if (talent) {
        Loader.turnOn();
        return await API.Talents.getTalentFinancialDocs(talent.talentId)
          .then(res => setFinancialDocsResponse(res))
          .finally(() => Loader.turnOff());
      }
    },
    [talent],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    isLoading: Loader.isTurn,
    financialDocsResponse,
    reloadDocs: fetch,
  };
};
export default useTalentFinancialDocs;
