import React, {useMemo} from 'react';
import Component from './login.styled';
import {Box, Button, Input, Loader, Notification, Text} from '@/Website/Components/UI';
import {useFireBaseAuth} from '@/Hooks';
import {useRootStore} from '@/Store/root/root.selector';
import {Formik, Form, Field} from 'formik';
import {LoginService} from './login.service';

const Login: React.FC = () => {
  const {initialValues, validationSchema} = useMemo(() => LoginService, []);
  const firebaseLogin = useFireBaseAuth();
  const {
    notification: {isShowNotification},
  } = useRootStore().selectors;

  const loginHandler = async (values: {email: string; password: string}) => {
    await firebaseLogin({email: values.email, password: values.password});
  };

  return (
    <Component className="Login">
      {isShowNotification && <Notification />}
      <Loader />

      <Box className="wrapper">
        <div className="greeting">
          <Text type="Header1" fontWeight={800}>
            Sign In
          </Text>
          <Text type="Text1">Enter your email and password to access admin panel</Text>
        </div>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={loginHandler}>
          {({errors, touched}) => (
            <Form className="form">
              <Field
                name="email"
                autoFocus
                placeholder="Email"
                type={'email'}
                as={Input}
                Error={touched.email && errors.email}
              />
              <Field
                name="password"
                placeholder="Password"
                type={'password'}
                as={Input}
                Error={touched.password && errors.password}
              />
              <Button isDisabled={Object.keys(errors).length > 0} color="DarkBlue" type="submit">
                Enter
              </Button>
            </Form>
          )}
        </Formik>

        <Text type="Text4" textAlign="right">
          ©LetsTok 2023 agencies
        </Text>
      </Box>
    </Component>
  );
};

export default Login;
