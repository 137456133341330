import {TableHead, TableCell} from '@material-ui/core';
import React, {memo} from 'react';
import styled from 'styled-components';
import {TCell, TRow} from '@/Website/Components/UI';

const SCompactCell = styled(TableCell)`
  font-size: 0.7rem !important;
  background-color: #d4fcee !important;
  color: rgb(1 1 1 / 87%) !important;
  font-weight: 600 !important;
  line-height: 0 !important;
`;

const TSmartHead: React.FC<{headers: Array<string>; isSmall?: boolean}> = ({headers, isSmall}) => {
  return (
    <TableHead>
      <TRow>
        {headers.map((header, i) =>
          isSmall ? <SCompactCell key={i}>{header}</SCompactCell> : <TCell key={i}>{header}</TCell>
        )}
      </TRow>
    </TableHead>
  );
};
export default memo(TSmartHead);
