import React, {ReactNode} from 'react';
import SCurtain from './curtain.styled';
import {ElementHeadline} from '@/Website/Components/UI';
import {ElementHeadlineProps} from '@/Website/Components/UI/ElementHeadline/ElementHeadline';
import {useClickOut} from '@/Hooks';

export interface CurtainConfig {
  width: string;
  height: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  slideFrom?: 'left' | 'right' | 'top' | 'bottom';
  disableAnimation?: boolean;
  disableClickOut?: boolean;
}
interface CurtainProps {
  headLineProps: Omit<ElementHeadlineProps, 'onClose'> & Required<Pick<ElementHeadlineProps, 'onClose'>>;
  curtainConfig: CurtainConfig;
  children: ReactNode;
}
const Curtain: React.FC<CurtainProps> = props => {
  const {headLineProps, curtainConfig, children} = props;
  const {ref} = useClickOut(() => headLineProps.onClose());
  return (
    <SCurtain className="SCurtain" {...curtainConfig}>
      <div className="curtain-window" ref={!curtainConfig.disableClickOut ? ref : null}>
        <ElementHeadline {...headLineProps} />
        <div className="curtain-body">{children}</div>
      </div>
    </SCurtain>
  );
};
export default Curtain;
