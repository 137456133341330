import React, {memo, useMemo} from 'react';
import {OrdersService} from '@/Website/Pages/Dashboard/Orders';
import {
  Avatar,
  Div,
  Label,
  SocialIcon,
  TableContainer,
  TCell,
  Text,
  Tooltip,
  TRow,
} from '@/Website/Components/UI';
import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';
import {useOpenOrder} from '@/Website/Components/Portals/Order';
import {Order} from '@/API/Orders/orders.types';
import {SocialService} from '@/Services/social.service';

interface Props {
  orders: Order[];
  isLoading: boolean;
}
const OrdersTable: React.FC<Props> = props => {
  const tableHeaders = useMemo(() => OrdersService.ordersTableHeaders, []);
  const {orders, isLoading} = props;
  const openOrder = useOpenOrder();
  return (
    <TableContainer headers={tableHeaders} stickyHeader isLoading={isLoading}>
      {orders.map((order, i) => (
        <TRow
          key={i}
          onClick={e => {
            e.stopPropagation();
            openOrder(order);
          }}
        >
          <TCell>
            <Text type="Text3">{order.id}</Text>
          </TCell>
          <TCell>
            <Tooltip title={order.talentInfo.url}>
              <SocialIcon
                onClick={e => {
                  e.stopPropagation();
                  void SocialService.handlePlatformClick(order.talentInfo.url);
                }}
                isActive
                social={order.talentInfo.socialPlatform}
                size="medium"
              />
            </Tooltip>
          </TCell>
          <TCell>
            <Label backgroundColor={ColorService.getColorTypeByStatus(order.status)}>{order.status}</Label>
          </TCell>

          <TCell>
            <Div alignItems="center" gap={10}>
              <Avatar
                image={order?.talentProfile?.avatar}
                borderColor={ColorService.getColorTypeByStatus(order.status)}
                size="medium"
              />
              <Text type="Text3">{order?.talentProfile?.user.fullName}</Text>
            </Div>
          </TCell>
          <TCell>
            <Text color="Purple" type="Text3">
              {order.campaign.id + ':' + order.campaign.title}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" fontWeight={800} color="Orange">
              {order.talentPrice || 0}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" fontWeight={800} color="Alert">
              {order.advertiserPrice || 0}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" color="Success">
              {dateToLocalDate(order.createdAt)}
            </Text>
          </TCell>
        </TRow>
      ))}
    </TableContainer>
  );
};
export default memo(OrdersTable);
