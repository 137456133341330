import styled, {css} from 'styled-components';

export const Component = styled.div<{isUploaded: boolean}>`
  ${({theme, isUploaded}) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 5px;
    position: relative;
    min-height: 45px;
    .button-label {
      border: none;
      padding: 3px 9px;
      border-radius: 15px;
      font-family: ${theme.FontFamily.Outfit};
      background-color: ${isUploaded ? theme.Color.Success : theme.Color.Orange};
      color: white;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
    .upload-file,
    .upload-directory {
      position: absolute;
      visibility: hidden;
    }
    .chooser-file-name {
      font-size: 0.6rem;
      font-family: ${theme.FontFamily.Outfit};
      margin-top: 2px;
      position: relative;
    }
  `}
`;
