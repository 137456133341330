import styled, {css} from 'styled-components';
import {memo} from 'react';
import {LabelProps} from '@/Website/Components/UI/Label/Label';

const SLabel = styled.div<LabelProps>`
  ${({theme, backgroundColor, fontColor}) => css`
    padding: 1px 5px;
    width: max-content;
    max-width: 150px;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
    color: ${fontColor ? theme.Color[fontColor] : theme.Color.White};
    font-family: ${theme.FontFamily.Outfit};
    font-size: 0.6rem;
    background-color: ${backgroundColor ? theme.Color[backgroundColor] : theme.Color.MediumGray};
  `}
`;

export default memo(SLabel);
