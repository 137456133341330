import styled from 'styled-components';

export const STalentTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 920px;
  height: 570px;
  padding: 20px 10px 20px;
  background-color: #ffffff;
`;
