import styled, {css} from 'styled-components';
export interface OptionType<T> {
  label: string;
  value: T;
}
export interface SelectOptionsType<T = any> extends Array<OptionType<T>> {}

export const SSelect = styled.div<{isCloseToBottom: boolean}>`
  ${({theme, isCloseToBottom}) => css`
    font-family: ${theme.FontFamily.Outfit};
    width: 100%;
    /* max-width: 350px; */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .select-label {
      font-family: ${theme.FontFamily.Outfit};
      color: #a1a1a1;
      font-size: 0.6rem;
    }
    .css-b62m3t-container {
      min-width: 150px;
    }
    .react-select__control {
      border: 1px solid #e7e6e6;
      box-shadow: none;
      font-size: 0.7rem;
      border-radius: 15px;
      background-color: #f9f9f9;
      &:hover {
        border-color: #e7e6e6;
      }
    }
    .css-1s2u09g-control {
      border: 1px solid #e7e6e6;
      background-color: #f9f9f9;

      font-size: 0.7rem;
    }
    .react-select__menu {
      z-index: 9999;
      font-size: 0.7rem;
      max-height: 170px;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .react-select__menu-list {
      overflow: scroll;
      max-height: 170px;
      z-index: 9999;
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-left: 0;
        border-right: 0;
        background: ${theme.Color.GrayLight};
      }
    }
    .react-select__option:hover {
      background-color: ${theme.Color.GrayLight};
      color: ${theme.Color.DarkGray};
    }
    .react-select__option--is-selected {
      background-color: ${theme.Color.DarkBlue} !important;
      color: ${theme.Color.MediumGray};
    }
    .react-select__option--is-focused {
      background-color: ${theme.Color.GrayLight};
    }
    .react-select__value-container--is-multi {
      max-height: 74px;
      overflow: auto;
      max-width: 200px;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .react-select__multi-value {
      border-radius: 10px;
      background-color: ${theme.Color.White};
      box-shadow: 0 1px 4px ${theme.Color.GrayLight};
    }
    ${isCloseToBottom &&
    css`
      .react-select__menu {
        position: absolute !important;
        top: -180px;
      }
    `}
  `}
`;
