import React, {useEffect, useState, useRef} from 'react';
import ReactSelect from 'react-select';
import {SelectOptionsType, SSelect} from './select.styled';

export type SelectProps = {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  options: SelectOptionsType;
  label?: string;
  onChange?: (value: any) => void;
  isMulti?: boolean;
  value: any;
  defaultPlaceholder?: any;
  focus?: boolean;
};
/** @param props.isMulti on that choice provides value like an array of {value: "", label: ""} */

const Select: React.FC<SelectProps> = props => {
  const {label, onChange, focus, defaultPlaceholder, ...restProps} = props;
  const selectRef = useRef<any>(null);
  const [isCloseToBottom, setIsCloseToBottom] = useState<boolean>(false);
  const [inputPlaceHolder, setInputPlaceHolder] = useState<string>('');
  const handlerChange = (e: any) => {
    setInputPlaceHolder(e.label);
    if (onChange) {
      restProps.isMulti ? onChange(e) : onChange(e.value);
    }
  };
  useEffect(() => {
    !restProps.value && setInputPlaceHolder(restProps.options[0].label);
  }, [restProps.value]);

  useEffect(() => {
    if (defaultPlaceholder) setInputPlaceHolder(defaultPlaceholder);
  }, [defaultPlaceholder]);

  useEffect(() => {
    const Y = selectRef.current.getBoundingClientRect().y;
    setIsCloseToBottom(window.screen.height - Y < 350);
  }, []);
  return (
    <SSelect className="SingleSelect" ref={selectRef} isCloseToBottom={isCloseToBottom}>
      <div className="select-label">{label}</div>
      <ReactSelect
        {...restProps}
        classNamePrefix="react-select"
        onChange={handlerChange}
        autoFocus={focus || false}
        placeholder={inputPlaceHolder}
        // onInputChange={handlerChange}
      />
    </SSelect>
  );
};

export default Select;
