import eyeClosedIcon from '@/Assets/Icons/eye-closed.svg';
import eyeOpenedIcon from '@/Assets/Icons/eye-opened.svg';
import mailIcon from '@/Assets/Icons/mail.svg';
import passwordIcon from '@/Assets/Icons/password.svg';
import searchImg from '@/Assets/Icons/search.svg';
import {useBooleanState} from '@/Hooks/useBooleanState';
import React, {useCallback} from 'react';
import Text from '../Text/Text';
import {Props} from './input.interface';
import {Component} from './input.styled';

/**@type Date wrap incoming value with Function - dateAdapter from date service  */

const Input: React.FC<Props> = props => {
  const ShowPassword = useBooleanState();
  const {placeholder, Error, disabled, type, searchIcon, value, name, ...propsRest} = props;

  const eyeClickHandler = useCallback(() => {
    type === 'password' && ShowPassword.toggle();
  }, [type, ShowPassword]);
  const handleClearInput = useCallback(
    (e: any) => {
      e.stopPropagation();
      const event = {
        target: {
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>;

      propsRest.onChange && propsRest.onChange(event);
    },
    [propsRest]
  );
  const isHasValue = value ? value.toString().length > 0 : false;
  return (
    <Component
      isDisabled={disabled || false}
      isError={!!Error || false}
      className="Input-Wrap"
      withoutIcon={!type && !searchIcon}
    >
      <div className="input-group">
        <input
          className="input"
          placeholder={placeholder}
          required
          autoComplete="off"
          name={name || 'boo'}
          disabled={disabled}
          value={value ?? ''}
          {...propsRest}
          type={
            //to avoid 'email' default validation
            ShowPassword.isTurn || type === 'email' ? 'text' : type
          }
        />

        <label className="input-label">{placeholder}</label>
        {type === 'password' && (
          <>
            <img
              src={ShowPassword.isTurn ? eyeOpenedIcon : eyeClosedIcon}
              className="eye"
              onClick={eyeClickHandler}
              alt=""
            />
            <img src={passwordIcon} className="password" alt="" />
          </>
        )}
        {type === 'email' && <img src={mailIcon} className="mail" alt="" />}
        {searchIcon && <img src={searchImg} className="mail" alt="" />}
        {isHasValue ? (
          <div className="clear-input" onClick={handleClearInput}>
            x
          </div>
        ) : null}
      </div>
      {Error && (
        <Text type="Text3" color="Alert" className="error-msg">
          {Error}
        </Text>
      )}
    </Component>
  );
};

export default Input;
