import styled, {css} from 'styled-components';

const STextEditor = styled.div<{height?: string}>`
  ${props => css`
    gap: 1px;
    height: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    .react-quill {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    .ql-toolbar.ql-snow {
      outline: 1px solid #e7e6e6;
      border-radius: 15px 15px 0 0;
      background-color: #eaecec;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 0 0 15px 15px;
      max-height: 100%;
      overflow: auto;
      background-color: #ffffff;
    }
    .ql-editor {
      background-color: #ffffff;
      border-radius: 0 0 15px 15px;
      max-height: 100%;
      ${props.theme.Presets.scroll}
      height: ${props.height || 'max-content'};
    }
    .react-quill .ql-editor {
      color: #000000; /* Set the default text color to black */
    }
  `}
`;
export default STextEditor;
