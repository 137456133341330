import React, {useMemo, useCallback} from 'react';
import {
  Avatar,
  ClipboardText,
  ContextMenu,
  Div,
  TableContainer,
  TCell,
  Text,
  Tooltip,
  TRow,
} from '@/Website/Components/UI';
import {TalentsService, useDeleteTalents} from '@/Website/Pages/Dashboard/Talents';
import {Talent} from '@/API/Talents/talents.types';
import {ReactComponent as BioIcon} from '@/Assets/Icons/info.svg';
import {UtilService} from '@/Services/util.service';
import {useOpenTalent} from '@/Website/Components/Portals/Talent';

interface Props {
  isLoading: boolean;
  talents: Talent[];
}
const TalentsTable: React.FC<Props> = props => {
  const {isLoading, talents} = props;
  const openTalent = useOpenTalent();
  const tableHeaders = useMemo(() => TalentsService.tableHeaders, []);
  const deleteTalent = useDeleteTalents();

  const rowOptions = useCallback(
    (talentId: string, fullName?: string) => [
      {option: `Remove ${fullName || ''}`, onSelect: () => deleteTalent([talentId])},
    ],
    []
  );
  return (
    <TableContainer headers={tableHeaders} stickyHeader isLoading={isLoading}>
      {talents.map((talentItem, i) => (
        <TRow key={i}>
          <TCell>
            <Avatar
              onClick={() => openTalent(talentItem)}
              borderColor="Blue"
              size="big"
              image={talentItem?.talent?.avatar}
            />
          </TCell>
          <TCell>
            <Div flexDirection="column">
              <Text type="Text2" fontWeight={800} color="BlackOcean">
                {talentItem?.talent?.user.fullName}
              </Text>
              <ClipboardText textToCopy={talentItem?.talent?.user.email || ''}>
                <Text type="Text3" color="MediumGray">
                  {talentItem?.talent?.user.email}
                </Text>
              </ClipboardText>
            </Div>
          </TCell>

          <TCell>
            <Text type="Text3">{UtilService.getCountryByCountryCode(talentItem?.talent?.user.country)}</Text>
          </TCell>
          <TCell>
            {talentItem?.talent?.bio && (
              <Tooltip title={talentItem?.talent?.bio}>
                <BioIcon style={{height: 20}} />
              </Tooltip>
            )}
          </TCell>
          <TCell>
            <ContextMenu options={rowOptions(talentItem.talentId, talentItem.talent?.user.fullName)} />
          </TCell>
        </TRow>
      ))}
    </TableContainer>
  );
};

export default TalentsTable;
