import SAvatar from './avatar.styled';
import noAvatarImg from '@/Assets/Images/no_avatar.png';
import React, {useMemo, useState} from 'react';
import {ColorType} from '@/Styles/types';
import {CONFIG_ENV} from '@/Config/config.env';

export interface AvatarProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  image: string | undefined;
  size?: 'small' | 'medium' | 'big' | 'large' | 'xxxl';
  lazy?: boolean;
  borderColor?: ColorType;
  objectFit?: 'contain' | 'cover' | 'fill';
}

const Avatar: React.FC<AvatarProps> = props => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const {image, size, borderColor, objectFit, lazy, ...rest} = props;
  const path = useMemo(
    () => (image && !image.includes('http') ? CONFIG_ENV('MEDIA_ROUTE') + image : image),
    [image]
  );
  return (
    //@ts-ignore
    <SAvatar
      {...rest}
      size={size || 'medium'}
      isLoaded={isLoaded}
      objectFit={objectFit}
      borderColor={borderColor}
    >
      <img
        src={(image && path) || noAvatarImg}
        alt="influencer"
        loading={lazy ? 'lazy' : 'eager'}
        onLoad={() => setIsLoaded(true)}
        onError={(e: any) => ((e.target.onerror = null), (e.target.src = noAvatarImg))}
      />
    </SAvatar>
  );
};
export default Avatar;
