import {useEffect} from 'react';
import {useRootStore} from '@/Store/root/root.selector';
import {StorageService} from '@/Services/storage.service';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import LoaderService from '@/Services/loader.service';
import {useSetAuth} from '@/Hooks';

const useFirebaseInit = () => {
  const {setIsAuth} = useRootStore().actions;

  const setAuth = useSetAuth();
  useEffect(() => {
    const auth = getAuth();
    LoaderService.setLoading(true);
    onAuthStateChanged(auth, user => {
      if (user) {
        user.getIdToken(true).then(async token => {
          StorageService.set('TOKEN', token);
          await setAuth();
        });
      } else {
        StorageService.delete('TOKEN');
        setIsAuth(false);
      }
      LoaderService.setLoading(false);
    });
  }, []);
};
export default useFirebaseInit;
