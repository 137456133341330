import React from 'react';
import {ElementHeadline} from '@/Website/Components/UI';
import {useCloseTalent} from '@/Website/Components/Portals/Talent';
import {TalentDetailed} from '@/API/Talents/talents.types';

interface Props {
  talent: TalentDetailed;
  onSave: Function;
}
const TalentTitleBar: React.FC<Props> = ({talent, onSave}) => {
  const closeModal = useCloseTalent();
  return (
    <ElementHeadline
      disableMarker
      fontSize="Header4"
      onSave={onSave}
      onClose={closeModal}
      title={talent.fullName}
    />
  );
};
export default TalentTitleBar;
