import React from 'react';
import {Box, Div, TitledText} from '@/Website/Components/UI';
import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';
import {OrderDetailed} from '@/API/Orders/orders.types';

interface Props {
  campaign?: OrderDetailed['campaign'];
  isLoading: boolean;
}
const OrderCampaign: React.FC<Props> = ({campaign, isLoading}) => {
  return (
    <Box label="Campaign">
      <TitledText
        style={{flexDirection: 'column'}}
        title={{value: 'Status'}}
        text={{
          type: 'Header4',
          color: ColorService.getColorTypeByStatus(campaign?.status),
          value: campaign?.status.toUpperCase(),
          isLoading,
        }}
      />
      <Div justifyContent="space-between">
        <TitledText
          style={{flexDirection: 'column'}}
          title={{value: 'Name'}}
          text={{color: 'Purple', value: campaign?.title, isLoading}}
        />
        <TitledText
          style={{flexDirection: 'column'}}
          title={{value: 'Created at'}}
          text={{
            color: 'Success',
            value: dateToLocalDate(campaign?.createdAt as undefined),
            isLoading,
          }}
        />
      </Div>
    </Box>
  );
};
export default OrderCampaign;
