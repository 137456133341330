import {Loader} from '@/Website/Components/UI';
import {Suspense} from 'react';

export default function WithLazy<P>(Component: React.ComponentType & any) {
  return function WithSuspense(props: P) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
