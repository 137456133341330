import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import {useRootStore} from '@/Store/root/root.selector';
import {Confirmation, Loader, MediaPlayer, Notification} from '@/Website/Components/UI';
import React, {memo} from 'react';
import TopBar from './TopBar/TopBar';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Order, Talent} from '@/Website/Components/Portals';

const Component = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  .outlet {
    flex: 1;
    height: 100%;
  }
`;

const Layout: React.FC = () => {
  const {
    confirmation: {isShowConfirmation},
    notification: {isShowNotification},
    mediaPlayer: {isShowMediaPlayer},
  } = useRootStore().selectors;
  const {
    orderPortal: {isShowOrderPortal},
    talentPortal: {isShowTalentPortal},
  } = usePortalsStore().selectors;
  return (
    <Component className="Layout" id="root-layout">
      <Loader />
      {isShowOrderPortal && <Order />}
      {isShowTalentPortal && <Talent />}
      <div className="outlet">
        <TopBar />
        <Outlet />
      </div>
      <Loader />
      {isShowNotification && <Notification />}
      {isShowConfirmation && <Confirmation />}
      {isShowMediaPlayer && <MediaPlayer />}
    </Component>
  );
};

export default memo(Layout);
