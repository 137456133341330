import React, {useCallback, useMemo} from 'react';
import {DocsEntity} from '@/API/Talents/talents.types';
import {ContextMenu, TableContainer, TCell, Text, TRow} from '@/Website/Components/UI';
import {useDeleteTalentFinancialDoc} from '@/Website/Components/Portals/Talent';
import {CONFIG_ENV} from '@/Config/config.env';

interface Props {
  isLoading: boolean;
  docs: DocsEntity[];
  afterDelete: Function;
}
const TalentFinancialDocsTable: React.FC<Props> = props => {
  const {afterDelete, docs, isLoading} = props;
  const tableHeaders = useMemo(() => ['ID', 'Type', 'Path', ''], []);
  const handleOpenDoc = useCallback(
    (doc: string) => window.open(CONFIG_ENV('MEDIA_ROUTE') + doc, '_blank'),
    []
  );
  const deleteDoc = useDeleteTalentFinancialDoc();
  const handleDeleteDoc = useCallback(async (docPath: string, id: number) => {
    await deleteDoc({docPath, id}).then(() => afterDelete());
  }, []);
  return (
    <TableContainer isSmall headers={tableHeaders} stickyHeader isLoading={isLoading}>
      {docs.map((doc, i) => (
        <TRow key={i}>
          <TCell>
            <Text type="Text3">{doc.id}</Text>
          </TCell>
          <TCell>
            <Text type="Text3" fontWeight={800} color="Orange">
              {doc.docType}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" color="Blue">
              {doc.docPath}
            </Text>
          </TCell>

          <TCell>
            <ContextMenu
              options={[
                {onSelect: () => handleOpenDoc(doc.docPath), option: 'Open'},
                {onSelect: () => handleDeleteDoc(doc.docPath, doc.id), option: 'Delete'},
              ]}
            />
          </TCell>
        </TRow>
      ))}
    </TableContainer>
  );
};
export default TalentFinancialDocsTable;
