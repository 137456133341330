import styled, {css} from 'styled-components';
import React from 'react';
import {FlexProps} from '@/Styles/theme';

interface GridProps
  extends FlexProps,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  columnGap?: number;
  rowGap?: number;
  templateColumn?: string;
  templateRow?: string;
  width?: string;
}

const SGrid = styled.div<GridProps & FlexProps>`
  ${props => css`
    display: grid;
    ${props.alignItems && `align-items: ${props.alignItems};`}
    ${props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props.templateColumn && `grid-template-columns: ${props.templateColumn};`}
    ${props.columnGap && `grid-column-gap: ${props.columnGap}px;`}
    ${props.rowGap && `grid-row-gap: ${props.rowGap}px;`}
    ${props.templateRow && `grid-template-rows: ${props.templateRow};`}
    ${props.width && `width: ${props.width};`}
  `}
`;

const Grid: React.FC<GridProps> = props => {
  const {children, ...restProps} = props;
  return (
    //@ts-ignore
    <SGrid className="Grid" {...restProps}>
      {children}
    </SGrid>
  );
};
export default Grid;
