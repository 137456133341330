import React, {memo, useMemo, useState} from 'react';
import {Modal, Tabs, Panel} from '@/Website/Components/UI';
import {
  TalentDetails,
  TalentFinancialDocs,
  TalentOrders,
  TalentSocials,
  TalentTitleBar,
  TalentWallet,
  useTalent,
} from '@/Website/Components/Portals/Talent/index';
import {TalentService} from './talent.service';
import useUpdateTalent from '@/Website/Components/Portals/Talent/Hooks/useUpdateTalent';
import {TalentUpdateDTO} from '@/API/Talents/talents.types';

const Talent = () => {
  const {talent, reloadTalent} = useTalent();
  const {talentToUpdate, setTalentToUpdate, updateTalent} = useUpdateTalent(talent);
  const tabs = useMemo(() => TalentService.tabs, []);
  const [tabIndex, setTabIndex] = useState<number>(0);

  return talent && talentToUpdate ? (
    <Modal style={{backgroundColor: '#ECF1F6'}} isShowModal={true}>
      <TalentTitleBar talent={talent} onSave={updateTalent} />
      <Tabs index={tabIndex} setIndex={setTabIndex} tabsHeaders={tabs}>
        <Panel>
          <TalentDetails
            user={{email: talent.email, dateJoined: talent.dateJoined}}
            talentUpdate={talentToUpdate}
            setTalentUpdate={setTalentToUpdate as React.Dispatch<React.SetStateAction<TalentUpdateDTO>>}
          />
        </Panel>
        <Panel>
          <TalentSocials
            talentUpdate={talentToUpdate}
            setTalentUpdate={setTalentToUpdate as React.Dispatch<React.SetStateAction<TalentUpdateDTO>>}
          />
        </Panel>
        <Panel>
          <TalentOrders />
        </Panel>
        <Panel>
          <TalentWallet />
        </Panel>
        <Panel>
          <TalentFinancialDocs />
        </Panel>
      </Tabs>
    </Modal>
  ) : null;
};
export default memo(Talent);
