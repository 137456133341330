import {useApiLoader} from '@/Hooks';
import {TalentUpdateMediaDTO} from '@/API/Talents/talents.types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {S3Service} from '@/Services/s3.service';
import {API} from '@/API/API';

const useUploadTalentMedia = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  return useApiLoader(
    async (payload: {file: File; mediaType: TalentUpdateMediaDTO['mediaType']}) => {
      if (talent) {
        const {file, mediaType} = payload;

        const filename = file.name.replace(' ', '') || 'unnamed';
        const path = `profiles/${talent.talentId}/${mediaType}/${filename}`;

        return await S3Service.uploadFileToS3({key: path, file, secure: false}).then(async () => {
          await API.Talents.updateTalentMedia({
            mediaType,
            link: path,
            talentId: talent.talentId,
          }).catch(async err => {
            await S3Service.deleteFileFromS3(path, false);
            throw err;
          });
          return path;
        });
      }
    },
    [],
    {successMsg: `Successfully uploaded`}
  );
};
export default useUploadTalentMedia;
