import {useApiLoader} from '@/Hooks';
import {S3Service} from '@/Services/s3.service';
import {useRootStore} from '@/Store/root/root.selector';

const useUploadRoster = (callBack: Function) => {
  const {user} = useRootStore().selectors;
  return useApiLoader(
    async (file: File) => {
      if (user) {
        const filename = file.name.replace(' ', '').trim() || 'unnamed';
        const path = `talent-roster/${user?.id}/${filename}`;
        // return await API.Talents.uploadRoster(user.id, file).then(() => callBack());
        return await S3Service.uploadFileToS3({file, key: path, secure: false}).then(() => callBack());
      }
    },
    [callBack],
    {successMsg: 'Roster uploaded'}
  );
};
export default useUploadRoster;
