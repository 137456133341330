import React, {useCallback, useMemo} from 'react';
import {TalentUpdatable} from '@/Website/Components/Portals/Talent/talent.service';
import {SocialService} from '@/Services/social.service';
import {STalentTab} from '@/Website/Components/Portals/Talent/talent.styled';
import {Div, ElementHeadline, Grid, Input, SocialIcon} from '@/Website/Components/UI';

const TalentSocials: React.FC<TalentUpdatable> = ({talentUpdate, setTalentUpdate}) => {
  const socialsArray = useMemo(() => SocialService.SOCIALS_ARRAY, []);

  const handleSocialChange = useCallback(
    (key: keyof TalentUpdatable['talentUpdate']['profile'], value: any) => {
      setTalentUpdate(prevState => ({...prevState, profile: {...prevState.profile, [key]: value}}));
    },
    [setTalentUpdate]
  );

  return (
    <STalentTab>
      <ElementHeadline disableMarker title="Talent socials" />
      <Grid rowGap={30} templateColumn="repeat(2, 1fr)" columnGap={40}>
        {socialsArray.map((social, i) => (
          <Div gap={10} key={i} alignItems="flex-end">
            <SocialIcon
              social={social}
              size="big"
              isActive={!!talentUpdate.profile[social]}
              onClick={() =>
                talentUpdate.profile[social] &&
                window.open(SocialService.validateSocialLink(social, talentUpdate.profile[social]), '_blank')
              }
            />

            <Input
              placeholder={social}
              value={
                !!talentUpdate.profile[social]
                  ? talentUpdate.profile[social as keyof TalentUpdatable['talentUpdate']['profile']]
                  : ''
              }
              onChange={e => handleSocialChange(social, e.target.value)}
            />
          </Div>
        ))}
      </Grid>
    </STalentTab>
  );
};
export default TalentSocials;
