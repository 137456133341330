import {UTIL_CONSTANTS} from '@/Util/constants';
import {SelectOptionsType} from '@/Website/Components/UI/Select/select.styled';

export const SelectService = {
  convertStringArrayToSelectValue: (arr?: Array<string>) => {
    return arr
      ? arr.map(el => {
          return {label: el, value: el};
        })
      : [];
  },
  convertObjectKeysToSelectOption: (object: Object): SelectOptionsType => [
    ...Object.keys(object).map(el => ({label: el, value: el})),
  ],
  convertSelectOptionsToLabelsArray: (arr?: SelectOptionsType | undefined) => {
    if (!arr) return [];
    return arr.map(el => el.label);
  },
  convertSelectOptionsToValuesArray: (arr?: SelectOptionsType | undefined) => {
    if (!arr) return [];
    return arr.map(el => el.value);
  },
  convertCountryCodeToName: (code?: string) => {
    if (!code) return '';
    const country = UTIL_CONSTANTS.COUNTRIES.find(el => el.value === code);
    return country ? country.label : '';
  },
  convertCountryCodeArrayToSelectValue: (arr?: Array<string>) => {
    if (!arr || !arr.length) return [];
    const res: SelectOptionsType = [];
    UTIL_CONSTANTS.COUNTRIES.forEach(el => arr.includes(el.value) && res.push(el));
    return res;
  },
  convertLanguagesCodeArrayToSelectValue: (arr?: Array<string>) => {
    if (!arr || !arr.length) return [];
    const res: SelectOptionsType = [];
    UTIL_CONSTANTS.LANGUAGES.forEach(el => arr.includes(el.value) && res.push(el));
    return res;
  },
};
