import React from 'react';
import {Grid, Select} from '@/Website/Components/UI';
import {OrdersService, useOrganizationCampaigns} from '@/Website/Pages/Dashboard/Orders';
import {GenericSelect} from '@/Website/Components/Common';
import {useReduceState} from '@/Hooks';
interface Props {
  filters: typeof OrdersService.defaultTableFilterState;
  setFilters: React.Dispatch<React.SetStateAction<typeof OrdersService.defaultTableFilterState>>;
}
const OrdersFilters: React.FC<Props> = props => {
  const {campaignSelectOptions} = useOrganizationCampaigns();
  const {filters, setFilters} = props;
  const handleChange = useReduceState(setFilters);
  return (
    <Grid templateColumn="repeat(2, 200px)" columnGap={10}>
      <GenericSelect
        value={filters.orderStatus}
        onChange={value => handleChange('orderStatus', value)}
        optionsType="orderStatuses"
        label="Order status"
      />
      <Select
        options={campaignSelectOptions}
        value={filters.campaignID}
        label="Campaign"
        onChange={value => handleChange('campaignID', value)}
      />
    </Grid>
  );
};
export default OrdersFilters;
