import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {PatchOrderPassagePayload} from '@/API/AgencyTalents/agencyTalents.types';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useSetOrderPassage = (callBack?: Function) => {
  const {orderPortalCallBack, order} = usePortalsStore().selectors.orderPortal;

  return useApiLoader(
    async (payload: Pick<PatchOrderPassagePayload, 'passage'>) => {
      if (order?.talent && order?.id) {
        return API.Orders.patchOrderPassage({
          ...payload,
          talentId: order.talent,
          orderId: order.id,
        }).then(async () => {
          callBack && (await callBack());
          orderPortalCallBack && (await orderPortalCallBack());
        });
      }
    },
    [order]
  );
};
export default useSetOrderPassage;
