import youtubeIcon from '@/Assets/Icons/socials/youtube.svg';
import instagramIcon from '@/Assets/Icons/socials/instagram.svg';
import facebookIcon from '@/Assets/Icons/socials/facebook.svg';
import discordIcon from '@/Assets/Icons/socials/discord.svg';
import linkedinIcon from '@/Assets/Icons/socials/linkedin.svg';
import twitchIcon from '@/Assets/Icons/socials/twitch.svg';
import tiktokIcon from '@/Assets/Icons/socials/tiktok.svg';
import twitterIcon from '@/Assets/Icons/socials/twitter.svg';

export type Socials = {
  instagram?: string;
  tiktok?: string;
  youtube?: string;
  twitch?: string;
  facebook?: string;
  twitter?: string;
  discord?: string;
  linkedin?: string;
};

export type SocialsType = keyof Socials;

export class SocialService {
  static SOCIALS_ARRAY: Array<SocialsType> = [
    'instagram',
    'youtube',
    'tiktok',
    'twitch',
    'facebook',
    'twitter',
    'discord',
    'linkedin',
  ];
  static SOCIALS_SELECT_OPTIONS = [
    {label: 'Instagram', value: 'instagram'},
    {label: 'Youtube', value: 'youtube'},
    {label: 'TikTok', value: 'tiktok'},
    {label: 'Twitch', value: 'twitch'},
    {label: 'Facebook', value: 'facebook'},
    {label: 'Twitter', value: 'twitter'},
    {label: 'Discord', value: 'discord'},
    {label: 'Linkedin', value: 'linkedin'},
  ];
  static getIconBySocialName(social?: SocialsType) {
    if (!social) return '';
    switch (social) {
      case 'youtube':
        return youtubeIcon;
      case 'instagram':
        return instagramIcon;
      case 'tiktok':
        return tiktokIcon;
      case 'discord':
        return discordIcon;
      case 'facebook':
        return facebookIcon;
      case 'linkedin':
        return linkedinIcon;
      case 'twitch':
        return twitchIcon;
      case 'twitter':
        return twitterIcon;
      default:
        return youtubeIcon;
    }
  }
  static async handleOpenSocial(social: SocialsType, value?: string) {
    if (!value) return;
    await navigator.clipboard.writeText(value);
    window.open(this.validateSocialLink(social, value), '_blank');
  }
  static async handlePlatformClick(formattedUrl: string) {
    await navigator.clipboard.writeText(formattedUrl.toString() as string);
    window.open(formattedUrl, '_blank');
  }

  static validateSocialLink(social: SocialsType, value: any) {
    let resultLink = value;

    if (social === 'tiktok' || social === 'youtube') {
      if (!resultLink.includes('@')) resultLink = '@' + resultLink;
    }
    resultLink = resultLink.includes(social?.toLowerCase())
      ? resultLink
      : `https://${social}.com/${resultLink}`;
    if (!resultLink.includes('https')) resultLink = 'https://' + resultLink;
    return resultLink;
  }
}
