import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {S3Service} from '@/Services/s3.service';

const useUploadOrderMaterial = (callBack?: Function) => {
  const {order, orderPortalCallBack} = usePortalsStore().selectors.orderPortal;

  return useApiLoader(
    async (file: File) => {
      if (order?.id && order?.talent) {
        const filename = file.name.replace(' ', '').trim() || 'unnamed';
        const path = `orders/${order?.id}/${filename}`;
        return await S3Service.uploadFileToS3({file, key: path, secure: true}).then(async () => {
          return await API.Orders.uploadOrderMaterial({
            orderId: order.id,
            product: path,
            talentId: order.talent,
          })
            .then(async () => {
              orderPortalCallBack && (await orderPortalCallBack());
              callBack && (await callBack());
            })
            .catch(async err => {
              await S3Service.deleteFileFromS3(path, true);
              throw err;
            });
        });
      }
    },
    [order],
    {successMsg: 'Uploaded successfully'}
  );
};
export default useUploadOrderMaterial;
