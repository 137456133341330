import {useApiLoader} from '@/Hooks';
import {DeleteTalentFinancialDocParams} from '@/API/Talents/talents.types';
import {S3Service} from '@/Services/s3.service';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useDeleteTalentFinancialDoc = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  return useApiLoader(
    async (params: Omit<DeleteTalentFinancialDocParams, 'talentId'>) => {
      if (talent) {
        return await S3Service.deleteFileFromS3(params.docPath, true).then(async () => {
          return await API.Talents.deleteTalentFinancialDoc({...params, talentId: talent.talentId});
        });
      }
    },
    [],
    {successMsg: 'Deleted'}
  );
};
export default useDeleteTalentFinancialDoc;
