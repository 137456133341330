import React from 'react';
import {Div, Text} from '@/Website/Components/UI';
import {TextProps} from '../Text/Text';
import {DivProps} from '../Div/Div';

interface Props {
  style?: DivProps;
  title: TextProps & {value?: string | number};
  text: TextProps & {value?: string | number};
}
const TitledText: React.FC<Props> = ({text, title, style}) => {
  return (
    <Div {...style}>
      <Text type="Text3" children={title.value} {...title} />
      <Text type="Text2" fontWeight={800} children={text.value} {...text} />
    </Div>
  );
};
export default TitledText;
