import {OrderStatusType} from '@/Website/Pages/Dashboard/Orders/orders.service';

const OrderService = {
  getOrderResponseTextByOrderStatus: (status?: OrderStatusType): string | undefined => {
    switch (status) {
      case 'waiting-list':
        return 'Pending campaign approval';
      case 'campaign-full':
        return 'Campaign is currently full';
      case 'submitted':
        return 'Waiting campaign approval of the submitted post';
      case 'approved-video':
        return 'Submitted post is approved, please upload to social media and attach post link here';
      case 'tracking':
        return 'Post is being tracked for 30 days';
      case 'completed':
        return 'Campaign is completed';
      case 'revision':
        return 'The campaign reviewed the post you uploaded and have a few notes: ';
      case 'dispute':
        return 'The campaign reviewed the post you uploaded and rejected it';
      default:
        return undefined;
    }
  },
};
export default OrderService;
