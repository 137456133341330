import {CONFIG_ENV} from '@/Config/config.env';
import {UTIL_CONSTANTS} from '@/Util/constants';

export class UtilService {
  static getCountryByCountryCode(code?: string) {
    if (!code) return '';
    const country = UTIL_CONSTANTS.COUNTRIES.find(el => el.value.toLowerCase() === code?.toLowerCase());
    if (country) return country?.label;
    return '';
  }
  static openMedia(file: string) {
    file && window.open(CONFIG_ENV('MEDIA_ROUTE') + file, '_blank');
  }
  static checkIsUuid(value: string) {
    if (value === '') return true;
    const uuidRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    return uuidRegex.test(value) ? value : '';
  }
  static spaceReplaceToUnderScore(value: string) {
    return value ? value.replaceAll(' ', '_') : '';
  }
  static hundredSeparator(enteredNumber?: any) {
    if (enteredNumber) {
      enteredNumber = Math.ceil(Number(enteredNumber));
      if (enteredNumber < 1000) return enteredNumber;
      let res = '';
      let arr = enteredNumber.toString().split('');
      let counter = 0;
      arr.reverse().forEach((el: any) => {
        if (counter === 3) {
          counter = 0;
          res = ',' + res;
        }
        res = el + res;
        counter++;
      });
      return res;
    } else {
      return 0;
    }
  }
}
