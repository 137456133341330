import styled, {css} from 'styled-components';
import {memo} from 'react';
import {AvatarProps} from '@/Website/Components/UI/Avatar/Avatar';
import {ColorType} from '@/Styles/types';
interface SAvatarProps {
  objectFit: string;
  size: AvatarProps['size'];
  isLoaded: boolean;
  borderColor: ColorType;
}
const SAvatar = styled.div<SAvatarProps>`
  ${({theme, size, isLoaded, borderColor, objectFit}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    //cursor: pointer;
    overflow: hidden;
    ${!isLoaded &&
    css`
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      animation-name: ${theme.Animations.movingGradient};
      animation-duration: 14s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    `}
    border-radius: 100%;
    ${borderColor &&
    `
    border: 3px solid ${theme.Color[borderColor]};
    `}
    ${size === 'small' &&
    css`
      height: 20px;
      width: 20px;
    `}
    ${size === 'medium' &&
    css`
      height: 30px;
      width: 30px;
    `}
    ${size === 'big' &&
    css`
      height: 50px;
      width: 50px;
    `}
   
      ${size === 'large' &&
    css`
      height: 80px;
      width: 80px;
    `}
     
      ${size === 'xxxl' &&
    css`
      height: 250px;
      width: 250px;
    `}
      
    img {
      object-fit: ${objectFit || 'cover'};
      object-position: center;
      position: relative;
      border-radius: 100%;
      height: 100%;
      width: 100%;
    }
  `}
`;
export default memo(SAvatar);
