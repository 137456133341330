import ErrorPage from '@/Website/Pages/ErrorPage/ErrorPage';
import React, {ReactNode} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Component} from './page.styled';
import {Text, Div, ScrollUp} from '@/Website/Components/UI';

interface PageProps {
  title: string;
  pageDescription: string;
  children: ReactNode;
  icon?: ReactNode;
}
const Page: React.FC<PageProps> = props => {
  const {title, children, pageDescription, icon} = props;
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Component className="Page">
        <div className="header-wrap">
          {icon}
          <Div flexDirection="column">
            <Text type="Header3" className="title" color="BlackOcean" fontWeight={800}>
              {title}
            </Text>
            <Text type="Text3" className="sub-header" color="MediumGray">
              {pageDescription}
            </Text>
          </Div>
        </div>
        <div className="page-outlet">
          {children}
          <ScrollUp />
        </div>
      </Component>
    </ErrorBoundary>
  );
};
export default Page;
