import {Tab, TabList, TabPanel} from 'react-tabs';
import styled from 'styled-components';
import {Component} from './tabs.styles';
import React from 'react';

interface TabsProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  index: number;
  setIndex: (index: number) => void;
  tabsHeaders: Array<{
    label: string;
    icon: any;
  }>;
  children: React.ReactNode;
}
export const Panel = TabPanel;

const Tabs: React.FC<TabsProps> = props => {
  const {index, setIndex, children, onSelect, tabsHeaders, ...rest} = props;

  return (
    <Component className="STabs" selectedIndex={index} onSelect={setIndex} {...rest}>
      <TabList className="tab-list">
        {tabsHeaders.map(({icon: Icon, label}, i) => (
          <Tab key={i} className={i === index ? 'active' : ''}>
            <Icon />
            {label}
          </Tab>
        ))}
      </TabList>
      {children}
    </Component>
  );
};
export default Tabs;
