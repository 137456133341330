import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, Grid, Input, TitledText} from '@/Website/Components/UI';
import {useUpdateOrderPrice} from '@/Website/Components/Portals/Order';
import {UpdateOrderPricePayload} from '@/API/AgencyTalents/agencyTalents.types';
import ColorService from '@/Services/color.service';

interface Props {
  isLoading: boolean;
  orderDto: {
    price?: null | number;
    talentId: string;
    status?: string;
    orderId: number;
  };
}
const OrderPrice: React.FC<Props> = props => {
  const {orderDto, isLoading} = props;

  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const updateDto: UpdateOrderPricePayload = useMemo(
    () => ({...orderDto, price: currentPrice}),
    [currentPrice]
  );

  const updatePrice = useUpdateOrderPrice();
  const handlePriceChange = useCallback((value: number) => {
    setCurrentPrice(value < 0 ? 0 : value);
  }, []);

  useEffect(() => {
    if (typeof orderDto.price === 'number') setCurrentPrice(orderDto.price);
  }, []);

  const isDisableButton = orderDto.price === currentPrice || (currentPrice === 0 && !orderDto.price);
  return (
    <Box label="Order">
      <TitledText
        style={{flexDirection: 'column'}}
        title={{type: 'Text3', value: 'Status'}}
        text={{
          color: ColorService.getColorTypeByStatus(orderDto.status),
          type: 'Header4',
          value: orderDto?.status?.toUpperCase(),
          isLoading,
        }}
      />
      <Grid justifyContent="space-between" alignItems="flex-end" templateColumn="230px 130px">
        <Input
          onChange={e => handlePriceChange(Number(e.target.value))}
          value={currentPrice}
          type="number"
          placeholder="Price"
          disabled={isLoading}
        />
        {!isDisableButton && (
          <Button onClick={async () => await updatePrice(updateDto)} color="Success" size="medium">
            SAVE
          </Button>
        )}
      </Grid>
    </Box>
  );
};
export default OrderPrice;
