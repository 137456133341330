import SLabel from './label.styled';
import React, {memo} from 'react';
import {ColorType} from '@/Styles/types';
import {Text} from '@/Website/Components/UI';

export interface LabelProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  backgroundColor?: ColorType;
  fontColor?: ColorType;
  size?: 'small' | 'medium' | 'large';
  isLoading?: boolean;
}
const Label: React.FC<LabelProps> = props => {
  const {children, isLoading, backgroundColor, fontColor, ...restProps} = props;
  return (
    //@ts-ignore
    <SLabel className="Label" {...restProps} backgroundColor={backgroundColor} fontColor={fontColor}>
      {isLoading ? <Text isLoading={isLoading} type="Text3" /> : children}
    </SLabel>
  );
};

export default memo(Label);
