import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {CONFIG_ENV} from '@/Config/config.env';
import {CONTROLLERS} from '../CONTROLLERS';
import {
  OrderLinkPostDTO,
  OrderProductCreateDTO,
  PatchOrderPassagePayload,
  UpdateOrderPricePayload,
} from '@/API/AgencyTalents/agencyTalents.types';
import {
  OrderDetailed,
  OrganizationOrdersResponse,
  OrganizationOrdersSearchParams,
} from '@/API/Orders/orders.types';

class OrdersApi {
  readonly api: AxiosInstance = new FactoryAxios(
    CONFIG_ENV('ORGANIZATION_MS'),
    CONTROLLERS.AgencyTalents
  ).getInstance();

  async getOrderByTalentId(talentId: string, orderId: number): Promise<OrderDetailed> {
    return await this.api.get(`/order/${talentId}/${orderId}`).then(res => res.data);
  }
  //
  async patchOrderPassage(payload: PatchOrderPassagePayload): Promise<OrderDetailed> {
    const {talentId, orderId, passage} = payload;
    return await this.api.get(`respond/${talentId}/${orderId}`, {params: {status: passage, orderId}});
  }
  //
  async updateOrderPrice(payload: UpdateOrderPricePayload): Promise<OrderDetailed> {
    const {orderId, price, talentId} = payload;
    return await this.api.patch(`/order-price/${talentId}/${orderId}`, {price}).then(res => res.data);
  }
  //
  async getTalentOrderStats(talentId: string): Promise<any> {
    return await this.api.get(`/order-stats/${talentId}`).then(res => res.data);
  }
  //
  async getTalentOrderProducts(talentId: string, orderId: number): Promise<any> {
    return await this.api.get(`/order-products/${talentId}/${orderId}`).then(res => res.data);
  }

  //
  async uploadOrderMaterial(payload: OrderProductCreateDTO): Promise<any> {
    const {talentId, product, orderId} = payload;

    return await this.api.post(`/post-product/${talentId}/${orderId}`, {product}).then(res => res.data);
  }
  //
  async updateOrderPostLink(payload: OrderLinkPostDTO): Promise<OrderDetailed> {
    const {orderId, talentId, link} = payload;
    return await this.api.patch(`order-post-link/${talentId}/${orderId}`, {link}).then(res => res.data);
  }
  async getOrdersByOrganization(params: OrganizationOrdersSearchParams): Promise<OrganizationOrdersResponse> {
    return await this.api
      .get(`/orders/${params.organizationId}`, {
        params,
        baseURL: CONFIG_ENV('ORGANIZATION_MS') + '/' + CONTROLLERS.Agencies,
      })
      .then(res => res.data);
  }
  //
}

export default OrdersApi;
