import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {CONFIG_ENV} from '@/Config/config.env';
import {CONTROLLERS} from '../CONTROLLERS';
import {
  CreateTalentDto,
  DeleteTalentFinancialDocParams,
  FinancialDocsResponse,
  TalentDetailed,
  TalentSearchResponse,
  TalentsSearchParams,
  TalentUpdateDTO,
  TalentUpdateMediaDTO,
  TalentWallet,
} from '@/API/Talents/talents.types';

class TalentsApi {
  readonly api: AxiosInstance = new FactoryAxios(CONFIG_ENV('ORGANIZATION_MS')).getInstance();

  async getTalentsByOrganization(params: TalentsSearchParams): Promise<TalentSearchResponse> {
    return await this.api
      .get(`${CONTROLLERS.Agencies}/org-talents/${params.organizationId}`, {params})
      .then(res => res.data);
  }

  async getTalentById(talentId: string): Promise<TalentDetailed> {
    return await this.api.get(`${CONTROLLERS.AgencyTalents}/profile/${talentId}`).then(res => res.data);
  }

  async getTalentFinancialDocs(talentId: string): Promise<FinancialDocsResponse> {
    return await this.api

      .get(`${CONTROLLERS.AgencyTalents}/financial-docs/${talentId}`)
      .then(res => res.data);
  }

  async addTalentFinancialDoc(talentId: string, docPath: string): Promise<FinancialDocsResponse> {
    return await this.api
      .post(`${CONTROLLERS.AgencyTalents}/add-financial-doc/${talentId}`, {docPath})
      .then(res => res.data);
  }

  async deleteTalentFinancialDoc(params: DeleteTalentFinancialDocParams): Promise<FinancialDocsResponse> {
    const {id, docPath, talentId} = params;
    return await this.api
      .delete(`${CONTROLLERS.AgencyTalents}/financial-docs/${talentId}`, {
        data: {
          docPath,
          docId: id,
        },
      })
      .then(res => res.data);
  }

  async updateTalent(payload: TalentUpdateDTO, talentID: string) {
    return await this.api
      .post(`${CONTROLLERS.AgencyTalents}/update/${talentID}`, payload)
      .then(res => res.data);
  }

  async updateTalentMedia(payload: TalentUpdateMediaDTO): Promise<TalentDetailed> {
    const {talentId, ...rest} = payload;
    return await this.api
      .post(`${CONTROLLERS.AgencyTalents}/update/talent-media/${talentId}`, rest)
      .then(res => res.data);
  }

  async getTalentWallet(talentId: string): Promise<TalentWallet> {
    return await this.api.get(`${CONTROLLERS.AgencyTalents}/wallet/${talentId}`).then(res => res.data);
  }

  async createAgencyTalent(payload: CreateTalentDto): Promise<any> {
    const {organizationID, ...rest} = payload;
    return await this.api
      .post(`${CONTROLLERS.Agencies}/create-talent/${organizationID}`, rest)
      .then(res => res.data);
  }

  async deleteAgencyTalents(payload: {organizationID: number; talents: Array<string>}): Promise<any> {
    const {organizationID, talents} = payload;
    return await this.api
      .delete(`${CONTROLLERS.Agencies}/remove-talents/${organizationID}`, {
        data: {talents},
      })
      .then(res => res.data);
  }

  async uploadRoster(organizationID: number, file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('organizationID', organizationID.toString());
    return await this.api
      .post(`${CONTROLLERS.Agencies}/roster/${organizationID}`, formData)
      .then(res => res.data);
  }
}

export default TalentsApi;
