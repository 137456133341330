import * as yup from 'yup';

export const LoginService = {
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email')
      .matches(/^[^A-Z]*$/, 'Email must not contain uppercase letters')
      .required('Required'),
    password: yup.string().min(6, 'Must be at least 6 characters').required('Required'),
  }),

  initialValues: {email: '', password: ''},
};
