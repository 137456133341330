import {combineReducers, configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {portalsReducer} from './portals/portals.slice';
import {rootReducer} from './root/root.slice';

export const appReducer = combineReducers({
  root: rootReducer,
  portals: portalsReducer,
});

export const STORE = configureStore({
  reducer: appReducer,
  middleware: [thunk],
});
