import addNewIcon from '@/Assets/Icons/add_new.svg';
import clearIcon from '@/Assets/Icons/clear.svg';
import closeIcon from '@/Assets/Icons/close.svg';
import fullScreenIcon from '@/Assets/Icons/full_size.svg';
import moreIcon from '@/Assets/Icons/more.svg';
import saveIcon from '@/Assets/Icons/save.svg';
import searchIcon from '@/Assets/Icons/search.svg';
import {useClickOut} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {TextType} from '@/Styles/types';
import {Text, Tooltip} from '@/Website/Components/UI';
import React, {MouseEventHandler, memo, useCallback, useRef} from 'react';
import SElementHeadline from './elementHeadline.styled';

interface TooltipButtons {
  onCreateNew?: Function;
  onSave?: Function;
  onClose?: Function;
  onSearch?: Function;
  onClear?: Function;
  onSaveMsg?: string;
  onClearMsg?: string;
  onSearchMsg?: string;
  onCreateNewMsg?: string;
}
export interface ElementHeadlineProps extends TooltipButtons {
  title: string;
  options?: Array<{option: string; onSelect: Function}>;
  fullScreenOn?: boolean;
  fontSize?: TextType;
  isLoading?: boolean;
  disableMarker?: boolean;
}
const ElementHeadline: React.FC<ElementHeadlineProps> = props => {
  const {
    title,
    options,
    onCreateNew,
    fontSize,
    fullScreenOn,
    onClose,
    onSearch,
    onSave,
    disableMarker,
    onClear,
    isLoading,
    ...ToolTipMessages
  } = props;

  const ELEMENT_REF = useRef<any>(null);
  const ContextMenuShow = useBooleanState();
  const {ref} = useClickOut(ContextMenuShow.turnOff);

  const handleClickToFullScreen = useCallback(() => {
    /**class <fullscreen> defined in global.scss */
    ELEMENT_REF.current.parentNode.classList.toggle('fullscreen');
  }, []);
  //Handlers
  const handleClickCreateNew = useCallback(() => onCreateNew && onCreateNew(), [onCreateNew]);
  const handleClickSave = useCallback(() => onSave && onSave(), [onSave]);
  const handleClickClear = useCallback(() => onClear && onClear(), [onClear]);
  const handleClickSearch = useCallback(() => onSearch && onSearch(), [onSearch]);
  const handleClickClose = useCallback(() => onClose && onClose(), [onClose]);

  return (
    <SElementHeadline className="ElementHeader" ref={ELEMENT_REF} disableMarker={disableMarker || false}>
      <Text isLoading={isLoading} type={fontSize || 'Text2'} fontWeight={800}>
        {title}
      </Text>

      {options && ContextMenuShow.isTurn && (
        <div className="context-menu" ref={ref}>
          {options?.map(({option, onSelect}, i) => (
            <Text
              type="Text3"
              className="context-menu-option"
              onClick={onSelect as MouseEventHandler<HTMLSpanElement>}
              key={i}
            >
              {option}
            </Text>
          ))}
        </div>
      )}

      <div className="icons">
        {onClear && (
          <Tooltip title={ToolTipMessages.onClearMsg || `Clear filters`} tooltipType="tip">
            <img src={clearIcon} className="headline-icon" alt="" onClick={handleClickClear} />
          </Tooltip>
        )}
        {onSearch && (
          <Tooltip title={ToolTipMessages.onSearchMsg || 'Search'} tooltipType="tip">
            <img src={searchIcon} className="headline-icon" alt="" onClick={handleClickSearch} />
          </Tooltip>
        )}

        {onCreateNew && (
          <Tooltip title={ToolTipMessages.onCreateNewMsg || 'Create'} tooltipType="tip">
            <img src={addNewIcon} className="headline-icon" alt="" onClick={handleClickCreateNew} />
          </Tooltip>
        )}
        {onSave && (
          <Tooltip title={ToolTipMessages.onSaveMsg || 'Save'} tooltipType="tip">
            <img src={saveIcon} className="headline-icon" alt="" onClick={handleClickSave} />
          </Tooltip>
        )}
        {fullScreenOn && (
          <Tooltip title="Fullscreen" tooltipType="tip">
            <img src={fullScreenIcon} className="headline-icon" alt="" onClick={handleClickToFullScreen} />
          </Tooltip>
        )}
        {options && options.length > 0 && (
          <Tooltip title="Menu" tooltipType="tip">
            <img ref={ref} src={moreIcon} alt="" className="headline-icon" onClick={ContextMenuShow.toggle} />
          </Tooltip>
        )}
        {onClose && (
          <Tooltip title="Close" tooltipType="tip">
            <img src={closeIcon} className="headline-icon" alt="" onClick={handleClickClose} />
          </Tooltip>
        )}
      </div>
    </SElementHeadline>
  );
};

export default memo(ElementHeadline);
