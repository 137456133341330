class MediaService {
  static videoExt = ['mp4', 'mov'];
  static imageExt = ['jpg', 'bpm', 'png', 'jpeg'];

  static getExt(mediaName?: string) {
    if (!mediaName) return '';
    const ext = mediaName.split('.').pop();
    return !ext ? '' : ext;
  }
  static isMedia(media: string) {
    const ext = this.getExt(media);
    if (!ext) return false;
    return this.videoExt.includes(ext.toLowerCase()) || this.imageExt.includes(ext.toLowerCase());
  }
  static isVideo(media?: string) {
    const ext = this.getExt(media);
    if (!ext) return false;
    return this.videoExt.includes(ext.toLowerCase());
  }
}
export default MediaService;
