import {useApiLoader} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {S3Service} from '@/Services/s3.service';
import {API} from '@/API/API';

const useUploadTalentFinancialDoc = () => {
  const {talent} = usePortalsStore().selectors.talentPortal;
  return useApiLoader(
    async (file: File) => {
      if (talent) {
        const filename = file.name.replace(' ', '').trim() || 'unnamed';
        const path = `financial/${talent.talentId}/${filename}`;
        return await S3Service.uploadFileToS3({file, key: path, secure: true}).then(async () => {
          return await API.Talents.addTalentFinancialDoc(talent.talentId, path).catch(async err => {
            await S3Service.deleteFileFromS3(path, true);
            throw err;
          });
        });
      }
    },
    [talent],
    {successMsg: 'Uploaded'}
  );
};
export default useUploadTalentFinancialDoc;
