import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {TalentWallet} from '@/API/Talents/talents.types';

const useTalentWallet = () => {
  const [wallet, setWallet] = useState<TalentWallet>();
  const {talent} = usePortalsStore().selectors.talentPortal;
  const fetch = useApiLoader(async () => {
    if (talent) {
      await API.Talents.getTalentWallet(talent.talentId).then(res => setWallet(res));
    }
  }, [talent]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    wallet,
    reloadWallet: fetch,
  };
};
export default useTalentWallet;
