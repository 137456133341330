import React, {useEffect, useState} from 'react';

type UseDebouncedValueReturnType<T> = {
  debouncedValue: T | undefined;
  setDebouncedValue: React.Dispatch<React.SetStateAction<T | undefined>>;
};

const useDebouncedValue = <T>(dependency: T, delay: number = 500): UseDebouncedValueReturnType<T> => {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(dependency);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(dependency);
    }, delay);

    return () => clearTimeout(timer);
  }, [dependency, delay]);

  return {
    debouncedValue,
    setDebouncedValue,
  };
};

export default useDebouncedValue;
